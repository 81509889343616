import React from "react";
import classNames from "./style.module.scss";
import BasketItem from "../Basket/BasketItem";
import * as request from "../../../requests";
import { Button, CheckBox, Loader, RadioButton } from "../../../ui";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MapModal } from ".././MapModal";

interface Props {
  onCreate: any;
  onClose: any;
  openProduct: any;
}

interface State {
  loading: boolean;
  products: Array<any>;
  basket: Array<any>;
  totalSum: number;
  totalCount: number;
  delivery_date: string;
  client_phone_number: string;
  client_email: string;
  payTypes: Array<any>;
  payment_type_id: number;
  client_name: string;
  delivery_type: string;
  visible_map: boolean;
  client_address: string;
  store_id: string;
  store_name: string;
  mailing: boolean;
  disabledItem: boolean;
  errorCreate: boolean;
}

class Ordering extends React.Component<Props> {
  state: State = {
    loading: true,
    products: [],
    basket: [],
    totalSum: 0,
    totalCount: 0,
    delivery_date: moment().format("YYYY-MM-DD 23:59"),
    client_phone_number: "",
    client_email: "",
    payTypes: [],
    payment_type_id: 1,
    client_name: "",
    delivery_type: "pickup",
    visible_map: false,
    client_address: "",
    store_id: "",
    store_name: "",
    mailing: false,
    disabledItem: false,
    errorCreate: false,
  };

  componentDidMount() {
    let shop: any = JSON.parse(localStorage.getItem("shop_penny") || "null");
    if (shop != null)
      this.setState({ store_id: shop.id, store_name: shop.address }, () =>
        this.getBasket(shop.id)
      );
    request.getPays().then((json: any) => {
      this.setState({ payTypes: json.data });
    });
    this.getBasket();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let ordering = document.getElementsByClassName("ordering")[0];
    if (!event.path?.includes(ordering) && !this.state.visible_map)
      this.props.onClose();
  };

  getBasket = (shop?: string) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    // console.log("basket---------", basket);
    this.setState({ basket });
    let local_shop: any = JSON.parse(
      localStorage.getItem("shop_penny") || "null"
    );

    let params = {
      assortments: basket.map((item: any) => item.id),
      store: local_shop.id,
    };

    if (shop) params.store = shop;

    request
      .getBaskets(params)
      .then((json: any) => {
        let products = json.data;
        let totalSum = 0;
        let totalCount = 0;
        products.map((item: any, index: number) => {
          let newProduct = item;
          let newIndex = basket.findIndex((n: any) => n.id == item.id);
          newProduct.count = basket[newIndex].count;
          totalSum +=
            newProduct.count *
            (newProduct.is_discount == 1
              ? newProduct.price_discount
              : newProduct.price);
          totalCount += newProduct.count;
          if (newProduct.quantity.length == 0)
            this.setState({ disabledItem: true });
          if (shop) {
            newProduct.quantity_count =
              newProduct.quantity.length != 0
                ? newProduct.quantity[0].quantity
                : 0;
            if (newProduct.quantity_count < newProduct.count)
              this.setState({ disabledItem: true });
          }
          newProduct.img = "";
          if (item.contents)
            item.contents.forEach((element: any) => {
              if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
          return newProduct;
        });
        console.log(products);
        this.setState({ products, totalSum: totalSum.toFixed(2), totalCount });
      })
      .finally(() => this.setState({ loading: false }));
  };
  addCount = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);
    totalSum =
      totalSum -
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) *
      products[index].count;
    totalCount += 1;
    basket[index].count += 1;
    products[index].count += 1;
    totalSum =
      totalSum +
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) *
      products[index].count;
    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.checkOrder();
  };

  subCount = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);
    totalSum -=
      products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price;
    totalCount -= 1;
    basket[index].count -= 1;
    products[index].count -= 1;
    if (products[index].count == 0) {
      products.splice(index, 1);
      basket.splice(index, 1);
    }
    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.checkOrder();
  };

  deleteProduct = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);
    totalSum -=
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) * products[index].count;
    totalCount -= products[index].count;

    products.splice(index, 1);
    basket.splice(index, 1);

    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.checkOrder();
  };

  openMap = () => {
    this.setState({ visible_map: true });
  };

  closeMap = () => {
    setTimeout(() => {
      this.setState({ visible_map: false });
    }, 200);
  };

  checkOrder = () => {
    let index = this.state.products.findIndex(
      (item) => item.count > item.quantity_count
    );
    let index2 = this.state.products.findIndex(
      (item) => item.quantity.length == 0
    );

    this.setState({ disabledItem: index == -1 && index2 == -1 ? false : true });
  };

  createOrder = () => {
    this.setState({ loading: true }, () => {
      let {
        products,
        delivery_date,
        client_phone_number,
        client_email,
        payment_type_id,
        client_name,
        delivery_type,
        client_address,
        store_id,
      } = this.state;

      let assortments: { id: any; quantity: any; price: any }[] = [];
      products.forEach((item: any) => {
        if (item.quantity.length != 0) {
          let itemAssort = {
            id: item.id,
            quantity: item.count,
            price: item.is_discount == 1 ? item.price_discount : item.price,
          };
          assortments.push(itemAssort);
        }
      });

      let params = {
        delivery_date: delivery_date,
        client_email: client_email,
        client_bonus_card: "",
        client_phone_number: client_phone_number,
        client_name: client_name,
        delivery_type: delivery_type,
        store_id: store_id,
        client_address: client_address,
        region_id: "",
        payment_type_id: payment_type_id,
        assortments: assortments,
      };
      request
        .createOrder(params)
        .then((json: any) => {
          if (payment_type_id == 1) window.location.href = json.url;
          // console.log(json);
          // toast.success(json.data.status.name);
          else this.props.onCreate();
        })
        .catch(() => {
          this.setState({ loading: false, errorCreate: true });
        });
    });
  };

  changePeriod = (date: Date) => {
    this.setState({ delivery_date: moment(date).format("YYYY-MM-DD") });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let field = event.currentTarget.id;
    let value = event.currentTarget.value;
    if (field == "phone")
      this.setState({ client_phone_number: value.replace(/\D/g, "") });
    else if (field == "email") this.setState({ client_email: value });
    else if (field == "name") this.setState({ client_name: value });
  };

  choicePay = (id: number) => {
    this.setState({ payment_type_id: id });
  };

  choiceDelivery = (type: string) => {
    this.setState({ delivery_type: type });
  };

  choiceAddress = (type: string, address: any) => {
    // console.log("***");
    if (type == "shop") {
      localStorage.setItem("shop_penny", JSON.stringify(address));
      this.setState(
        {
          client_address: "",
          store_id: address.id,
          store_name: address.address,
          disabledItem: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ visible_map: false });
            this.getBasket(address.id);
          }, 200);
        }
      );
    } else {
      this.setState({
        client_address: address,
        store_id: 0,
        store_name: "",
      });
    }
  };

  clickCheck = () => {
    this.setState({ mailing: !this.state.mailing });
  };

  checkCreate = () => { };

  render() {
    let {
      loading,
      products,
      totalSum,
      totalCount,
      delivery_date,
      client_phone_number,
      client_email,
      payTypes,
      payment_type_id,
      client_name,
      delivery_type,
      visible_map,
      store_name,
      client_address,
      mailing,
      disabledItem,
      errorCreate,
    } = this.state;
    // console.log("***")

    return (
      <div className={classNames.container}>
        <div className={classNames.ordering + " ordering"}>
          <div className={classNames.flex + " " + classNames.top}>
            <div className={classNames.title_top}>Оформление заказа</div>
            <div
              className={classNames.close}
              onClick={this.props.onClose}
            ></div>
          </div>

          <Loader
            loading={loading}
          >
            {errorCreate && (
              <div className={classNames.error}>
                Ошибка создания заказа, повторите позднее
              </div>
            )}

            <div className={classNames.flex + " " + classNames.block_main}>
              <div className={classNames.left_block}>
                <div className={classNames.title}>Корзина</div>
                <div className={classNames.basket_items}>
                  {products.map((item: any) => {
                    return (
                      <BasketItem
                        id={item.id}
                        img={item.img}
                        price={item.price}
                        name={item.name}
                        is_discount={item.is_discount}
                        price_discount={item.price_discount}
                        count={item.count}
                        quantity_count={item.quantity_count}
                        addCount={this.addCount}
                        subCount={this.subCount}
                        deleteProduct={this.deleteProduct}
                        openProduct={() => this.props.openProduct(item.id)}
                        quantity={item.quantity.length == 0}
                      />
                    );
                  })}
                </div>
                <Button
                  title="Продолжить покупки"
                  onClick={this.props.onClose}
                  add={false}
                />
              </div>
              <div className={classNames.right_block}>
                <div className={classNames.title}>Подробности заказа</div>
                <div
                  className={
                    classNames.flex + " " + classNames.block_information
                  }
                >
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Товаров</div>
                    <div className={classNames.item_value}>{totalCount}</div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Вес, кг</div>
                    <div className={classNames.item_value}>-</div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Сумма заказа</div>
                    <div className={classNames.item_value}>{totalSum} ₽</div>
                  </div>
                </div>
                <div
                  className={
                    classNames.flex + " " + classNames.block_information
                  }
                >
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Способ доставки</div>
                    <div className={classNames.radio_groupe}>
                      <RadioButton
                        title="Самовывоз"
                        onclick={() => this.choiceDelivery("pickup")}
                        checked={delivery_type == "pickup"}
                      />
                      <RadioButton
                        title="Курьером"
                        onclick={() => this.choiceDelivery("courier")}
                        checked={delivery_type != "pickup"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Способ оплаты</div>
                    <div className={classNames.radio_groupe}>
                      {payTypes.map((item) => {
                        return (
                          <RadioButton
                            title={item.name}
                            onclick={() => this.choicePay(item.id)}
                            checked={item.id == payment_type_id ? true : false}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    classNames.flex +
                    " " +
                    classNames.block_information +
                    " " +
                    classNames.inputs_block +
                    " " +
                    classNames.address_name
                  }
                >
                  <div
                    className={classNames.block_information}
                    style={{ marginRight: 16, marginTop: 16 }}
                  >
                    <div className={classNames.item_button}>
                      <div className={classNames.item_title} style={{ marginBottom: 0 }}>
                        Адрес{delivery_type == "pickup" ? " магазина " : " "}
                      </div>
                      <div className={classNames.button} onClick={this.openMap}>
                        {store_name == "" ? "Выбрать" : "Изменить"}
                      </div>
                    </div>
                    <div className={classNames.store_name}>
                      {delivery_type == "pickup" ? store_name : client_address}
                    </div>
                  </div>
                  <div
                    className={
                      classNames.item_information +
                      " " +
                      classNames.nameCustomer
                    }
                  >
                    <div className={classNames.item_title}>Имя заказчика</div>
                    <input
                      id="name"
                      value={client_name}
                      onChange={this.changeInput}
                      placeholder={'Введите имя заказчика'}
                    ></input>
                  </div>
                </div>

                <div
                  className={
                    classNames.flex +
                    " " +
                    classNames.block_information +
                    " " +
                    classNames.inputs_block
                  }
                >
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Дата доставки</div>
                    <DatePicker
                      onChange={this.changePeriod}
                      locale={ru}
                      dateFormat="dd MMMM yyyy"
                      value={moment().format("DD.MM.YYYY")}
                      disabled
                    />
                  </div>
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Телефон</div>
                    {/* <input
                      id="phone"
                      value={client_phone_number}
                      onChange={this.changeInput}
                    ></input> */}
                    <InputMask
                      mask="+7 (999) 999-99-99"
                      maskChar=" "
                      value={client_phone_number}
                      id="phone"
                      onChange={this.changeInput}
                      placeholder={'Введите телефон'}
                    />
                  </div>
                  <div className={classNames.item_information}>
                    <div className={classNames.item_title}>Почта</div>
                    <input
                      id="email"
                      value={client_email}
                      onChange={this.changeInput}
                      placeholder={'Введите почту'}
                    ></input>
                  </div>
                </div>

                <CheckBox
                  title="Согласен на рассылку новостей"
                  checked={mailing}
                  onClick={this.clickCheck}
                />
                <div
                  className={classNames.flex + " " + classNames.block_bottom}
                >
                  <Button
                    title="Оформить заказ"
                    onClick={this.createOrder}
                    add={false}
                    // style={{ fontSize: 14, padding: "12px 20px", margin: 0 }}
                    disabled={
                      client_email.length == 0 ||
                      client_phone_number.length == 0 ||
                      delivery_date.length == 0 ||
                      client_name.length == 0 ||
                      disabledItem ||
                      (client_address.length == 0 && store_name.length == 0)
                    }
                  />
                  <div
                    className={
                      classNames.item_title + " " + classNames.add_conditional
                    }
                  >
                    Оформляя заказ, я принимаю{" "}
                    <a href="/policy">условия политики конфиденциальности</a>
                  </div>
                  {/* {disabledItem && (
                    <div
                      className={
                        classNames.item_title + " " + classNames.add_conditional
                      }
                    >
                      Обратите внимание, некоторые товары отсутствуют в
                      выбранном магазине или количество превышает имеющееся
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </Loader>
        </div>
        {visible_map && (
          <MapModal
            onClose={this.closeMap}
            choiceAddress={this.choiceAddress}
          />
        )}
      </div>
    );
  }
}

export default Ordering;
