import React from "react";
import * as request from "../../../requests";
import { Loader } from "../../../ui";
import classNames from "./style.module.scss";

interface Props {
  onClose: any;
  changeCity: any;
  cityId: number;
}
interface State {
  cities: Array<any>;
  loader: boolean;
}
class Cities extends React.Component<Props> {
  state: State = {
    cities: [],
    loader: true,
  };

  componentDidMount() {
    // console.log('-=-=-===-=-=-',localStorage.getItem("City_id"))
    request.getRegions().then((json: any) => {
      this.setState({ cities: json.data, loader: false });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let cities_block = document.getElementsByClassName("cities_block")[0];
    if (!event.path?.includes(cities_block)) this.props.onClose();
  };

  render() {
    let { cities, loader } = this.state;
    return (
      <div className={classNames.container}>
        <div className={classNames.main_container + " cities_block"}>
          <div className={classNames.flex + " " + classNames.top}>
            <div className={classNames.title_top}>Откуда вы?</div>
            <div
              className={classNames.close}
              onClick={this.props.onClose}
            ></div>
          </div>
          <div>
            <Loader loading={loader}>
              {cities.length != 0 ? (
                <div className={classNames.cities}>
                  {cities.map((item: any) => {
                    return item.childs.length > 0 ? (
                      <div className={classNames.items_block}>
                        <div className={classNames.subtitles}>{item.name}</div>
                        <div className={classNames.cities}>
                          {item.childs.map((child: any) => {
                            return (
                              <div
                                className={
                                  classNames.city +
                                  " " +
                                  (child.id == this.props.cityId ||
                                    child.id == localStorage.getItem("City_id")
                                    ? classNames.city_select
                                    : "")
                                }
                                onClick={() =>
                                  this.props.changeCity(child.name, child.id)
                                }
                              >
                                {child.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                        <div
                          className={
                            classNames.city +
                            " " +
                            (item.id == this.props.cityId ||
                              item.id == localStorage.getItem("City_id")
                              ? classNames.city_select
                              : "")
                          }
                          onClick={() =>
                            this.props.changeCity(item.name, item.id)
                          }
                        >
                          {item.name}
                        </div>
                      );
                  })}
                </div>
              ) : (
                  <div className="empty_block">Список регионов пуст</div>
                )}
            </Loader>
          </div>
        </div>
      </div>
    );
  }
}

export default Cities;
