import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Availability, СharacteristicItem } from "../../features";
import { PageContent, Button, PhotoBrower } from "../../ui";
import { RouteComponentProps } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import ReactNotification from 'react-notifications-component'
// import { store } from 'react-notifications-component';
import { EventEmitter } from "../../utils/helpers";

interface State {}
SwiperCore.use([Navigation, Pagination]);

class DeliveryPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {};

  componentDidMount() {
    EventEmitter.execute('scrollTo', { top: 0 });
  }

  render() {
    let {} = this.state;

    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
          <div className={classNames.title}>Контакты:</div>
          <div>
            <ol>
              <li>	ООО «Копейкин Дом» Юр. адрес: 356000, г. Новоалександровск, ул. Толстого, 121 ОГРН1072635017520 ИНН2635104976 КПП261501001 </li>
              <li>
                ООО «Копейкино» Адрес: 355012 г. Ставрополь ул. Ленина, 104 ОГРН 1102635005912 ИНН 2634089669 КПП 263401001
              </li>
              <li>
                ИП Попова Оксана Львовна ОГРН 311265131300447 ИНН 263512770837 Адрес: 355005, г. Ставрополь, проезд Каменный, 17
              </li>
              <li>
                ИП Золотых Валентина Ивановна ОГРН 309263502100131 ИНН 263505318370 Адрес: 355029 г. Ставрополь ул. Социалистическая 19 офис 12
              </li>
              <li>
                ООО «Территория хозяйки» Юр. адрес: 355040, г. Ставрополь ул. Доваторцев, 41г ИНН 2635136953 КПП263501001ОГРН 1102635010719
              </li>
              <li>
                ИП Попова Валентина Петровна ИНН 263505819190 ОГРНИП 312265124700459 Адрес: 355000, г. Ставрополь, пер. Мариупольский, 9
              </li>
            </ol>
          </div>
          <div className={classNames.title}>Оплата:</div>
          <div></div>
          <div>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата заказа банковской картой. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
          </div>
          <div>
              <img src={require("../../assets/images/mir.svg")}/>
              <img src={require("../../assets/images/visa.svg")}/>
              <img src={require("../../assets/images/mastercard.svg")}/>
              <img src={require("../../assets/images/jcb.svg")}/>
          </div>
          <div>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод специального пароля.
          </div>
          <div></div>
          <div>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB. 
          </div>
          <div className={classNames.title}>Доставка:</div>
          <div>
            <ol>
              <li>Доставка осуществляется до двери в выбранное вами время. </li>
              <li>
                Заказ осуществляется через веб-сайт shop.kopdom.ru положив товар
                в “корзину”.
              </li>
              <li>
                Время работы доставки: с 9:00 - 21:00. Интервал доставки 20
                минут.
              </li>
              <li>
                Стоимость доставки 200 рублей при заказе до 500 рублей. При
                заказе свыше 500 рублей доставка бесплатная.
              </li>
              <li>
                При нестандартном заказе свыше 10кг. стоимость доставки
                рассчитывается индивидуально.{" "}
              </li>
            </ol>
          </div>
          <div className={classNames.title}>Самовывоз:</div>
          <div>
            <ol>
              <li>При оформлении заказа товар резервируется на 24 часа.</li>
              <li>
                При оформлении заказа до 20:00, будет готов к выдаче с 9:00.
              </li>
              <li>
                При оформлении заказа после 20:00, будет доступен к выдаче с
                14:00.
              </li>
              <li>
                Оплата производится на месте получения заказа (Магазин “Копейкин
                Дом”).
              </li>
              <li>
                Зарезервированный заказ вправе получить сам заказчик или близкие
                люди с получения одобрения заказчика. О получении заказа другим
                человеком, предупреждать магазин не позже 30 минут до получения.
              </li>
              <li>Заказ формируется от 1-ой штуки товара. </li>
              <li>
                Когда ваш заказ будет собран, вы получите смс уведомление. Зака
                будет доступен к выдаче в течении 48 часов.
              </li>
            </ol>
          </div>
        {/* </div> */}
      </PageContent>
    );
  }
}
export default DeliveryPage;
