import React, { createRef, MouseEvent } from "react";
import classNames from "./style.module.scss";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import * as request from "../../../../requests";
import { isTemplateSpan } from "typescript";
import { observer } from "mobx-react";
import { appStore } from "../../../../stores";
import { Loader } from "../../../../ui";

interface Props {
  visible?: boolean;
  openCity: any;
  onCloseCatalog: any;
  openCategory: any;
}

interface State {
  items: Array<any>;
  openSubItem: any;
  openItemId: string;
  openSubItemId: string;
  openItem: any;
  shop: string;
  displayCatalogs: Array<any>;
  selectedCatalog: any;
  loading: boolean;
}

@observer
class Catalog extends React.Component<Props> {
  // public myRef = createRef<HTMLDivElement>();

  state: State = {
    items: [],
    openItemId: "",
    openSubItem: {},
    openSubItemId: "",
    openItem: {},
    shop: "",
    displayCatalogs: [],
    selectedCatalog: null,
    loading: true
  };

  containerRef: HTMLDivElement | null = null;

  componentDidMount = () => {
    request.getCatalogs().then((json: any) => {

      let categories = appStore.categories;
      json.data.forEach((element: any) => {
        categories.push(element);
        element.childs.forEach((child: any) => {
          categories.push(child);
          child.childs.forEach((subchild: any) => {
            categories.push(subchild);
          });
        });
      });
      appStore.setCategories(categories);
      localStorage.setItem("categories_penny", JSON.stringify(categories));

      this.setState({ items: json.data, displayCatalogs: json.data, loading: false });
    });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.visible === false && this.props.visible === true) {
      let shop: any = JSON.parse(localStorage.getItem("shop_penny") || "null");
      if (shop != null) this.setState({ shop: shop.address });
    }
  }

  handleCloseCatalog = () => {
    this.props.onCloseCatalog();
    this.setState({ selectedCatalog: null, displayCatalogs: this.state.items });
  };

  openCategory = (id: string) => {
    this.props.openCategory(id);
    this.handleCloseCatalog();
  };

  handleOpenSubitem = (item: any) => {
    this.setState({
      openSubItemId: (item.id != this.state.openSubItemId && item.id) || "0",
      openSubItem: item,
    });
  };

  handleOpenItem = (item: any) => {
    this.setState({
      openItemId: item.id || 0,
      openItem: item,
      openSubItemId: 0,
      openSubItem: {},
    });
  };

  handleClickCatalogMobile = (item: any) => {
    if (item.childs.length === 0) {
      this.openCategory(item.id);
    } else {

      this.setState({ selectedCatalog: item, displayCatalogs: item.childs }, () => {
        this.containerRef?.scrollTo({ top: 0 });
      });
    }
  }

  handleBackCatalog = () => {
    const { items, selectedCatalog } = this.state;
    const { parent_id } = selectedCatalog;

    const findParent = (id: number, array: Array<any>) => {
      let finded = null;
      array.forEach((el, indexEl) => {
        if (el.id === id) {
          finded = el;
        } else {
          if (el.childs.length > 0) {
            return findParent(id, el.childs);
          }
        }
      })

      return finded;
    }

    if (parent_id === null) {
      this.setState({ selectedCatalog: null, displayCatalogs: this.state.items });
    } else {
      let parentCatalog: any = findParent(parent_id, items);
      console.log(parentCatalog);
      this.setState({ selectedCatalog: parentCatalog, displayCatalogs: parentCatalog.childs });
    }
  }


  render() {
    let { visible, openCity, onCloseCatalog } = this.props;
    let { items, openItemId, openSubItemId, openSubItem, shop, displayCatalogs, selectedCatalog, loading } = this.state;
    return (
      <div
        className={visible ? classNames.menu_open : classNames.menu}
      >
        <div className={classNames.container_open}>
          <div className={classNames.items_ul}>
            {
              items.map((item, index) => {
                return (
                  <div key={item.id + '_catalog_element_' + index}>
                    <div
                      className={
                        classNames.item_container +
                        " " +
                        (openItemId == item.id ? classNames.select : "")
                      }
                      onClick={() => this.openCategory(item.id)}
                      onMouseOver={
                        window.screen.width > 680
                          ? () => this.handleOpenItem(item)
                          : () => { }
                      }
                      onMouseOut={
                        window.screen.width > 680
                          ? () => this.handleOpenItem(item)
                          : () => { }
                      }
                    >
                      <div className={classNames.catalog_item}>{item.name}</div>
                      <div
                        className={
                          classNames.arrow +
                          " " +
                          (openItemId == item.id && classNames.arrow_rotate)
                        }
                      ></div>
                    </div>

                    {openItemId == item.id && (
                      <div className={classNames.subcatalog}>
                        {item.childs.map((subitem: any) => {
                          return (
                            <div
                              onMouseOver={
                                window.screen.width > 680
                                  ? () => this.handleOpenSubitem(subitem)
                                  : () => { }
                              }
                              onClick={() => this.openCategory(subitem.id)}
                              className={
                                openSubItemId == subitem.id
                                  ? classNames.select
                                  : ""
                              }
                            >
                              <div className={classNames.subitem_title}>
                                {subitem.name}
                              </div>

                              {openSubItemId == subitem.id &&
                                window.screen.width <= 680 && (
                                  <div className={classNames.block_subitems}>
                                    {subitem.childs.map((subsubitems: any) => {
                                      return (
                                        <div className={classNames.subsub}>
                                          <Link
                                            to={"/products/" + subsubitems.id}
                                            onClick={() =>
                                              this.openCategory(subsubitems.id)
                                            }
                                          >
                                            {subsubitems.name}
                                          </Link>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>
          {openSubItem.childs && window.screen.width > 680 && (
            <div className={classNames.subsubcatalog}>
              {openSubItem.childs.map((item: any) => {
                return (
                  <div
                    className={classNames.subsub}
                    onClick={() => this.openCategory(item.id)}
                  >
                    <div
                    // to={"/products/" + item.id}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}


          {/* <div className={classNames.block_city}>
          Магазин:
            <span className={classNames.city} onClick={openCity}>
          </span>
        </div>
        <div className={classNames.catalog_block}>Каталог</div> */}


          <div className={classNames.mobile_header}>
            <div className={classNames.mobile_title}>Мой магазин</div>
            <div className={classNames.mobile_store} onClick={openCity}>{shop}</div>
            <div className={classNames.mobile_catalog_close} onClick={this.handleCloseCatalog}></div>
          </div>
          <div ref={node => this.containerRef = node} className={classNames.mobile_content}>
            <div className={classNames.mobile_catalog_title}>{selectedCatalog ? <span><div className={classNames.back_arrow} onClick={this.handleBackCatalog}></div> {selectedCatalog.name}</span> : 'Каталог'}</div>
            {
              loading === true ?
                <Loader style={{ marginTop: 50 }} />
                :
                displayCatalogs.map((item, index) => {
                  return (
                    <div key={'catalog_' + item.id} className={classNames.catalog_element + (item.childs.length === 0 ? '' : ' ' + classNames.with_arrow)} onClick={() => this.handleClickCatalogMobile(item)}>
                      <div className={classNames.mobile_name_catalog}>{item.name}</div>
                    </div>
                  )
                })
            }
          </div>
        </div>
      </div >
    );
  }
}

export default Catalog;
