import React from "react";
import classNames from "./style.module.scss";

interface Props {
  closePhoto: any;
  photos: any;
  photo: number;
}
interface State {
  current_photo: number;
}
class PhotoBrower extends React.Component<Props> {
  state: State = {
    current_photo: this.props.photo,
  };
  onClickNext = () => {
    this.setState(
      {
        current_photo:
          this.state.current_photo < this.props.photos.length - 1
            ? this.state.current_photo + 1
            : 0,
      },
      () => console.log(this.state.current_photo)
    );
  };
  render() {
    let { photo, photos } = this.props;
    let { current_photo } = this.state;
    return (
      <div className={classNames.container}>
        <div
          className={classNames.photo}
          onClick={this.onClickNext}
          style={{
            backgroundImage: "url(" + photos[current_photo] + ")",
            // backgroundColor: "red",
            // backgroundImage: "url(" + require("../../assets/images/sber.png") + ")",
          }}
        ></div>
        <div className={classNames.close} onClick={this.props.closePhoto}></div>
        <div className={classNames.button_block}>
          {photos.map((item: any, index: number) => {
            return (
              <div
                className={
                  index == current_photo
                    ? classNames.btn_active
                    : classNames.btn
                }
              ></div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PhotoBrower;
