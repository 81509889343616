import React, { Component } from "react";
import { Footer, Page } from "./ui";
import { Navbar } from "./features";
import "./assets/css/App.css";
import "./assets/css/colors.css";

import { MainRoute } from "./routes";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Page>
          <Navbar />
          <MainRoute />
          <Footer />
        </Page>
      </React.Fragment>
    );
  }
}

export default App;
