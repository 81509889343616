import React, { Component } from "react";
import classNames from "./style.module.scss";
import { PageContent, Button, Loader } from "../../ui";
import { Promotion } from "../../features";
import { RouteComponentProps } from "react-router-dom";
import * as request from "../../requests";
import { url } from "inspector";
import { EventEmitter } from "../../utils/helpers";

interface State {
  promo: any;
  loading: boolean;
}

class PromotionPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    promo: {},
    loading: true,
  };

  componentDidMount() {
    EventEmitter.execute('scrollTo', { top: 0 });
    request
      .getPromotion(this.props.match.params.id)
      .then((json: any) => {
        this.setState({ promo: json.data });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  openPromotion = (id: number) => {
    // this.props.history.push("/promotion/" + id);
  };

  onOpen = () => { };

  clickButton = () => {
    window.location.href = this.state.promo.action_url;
  };

  render() {
    let { promo, loading } = this.state;
    return (
      <PageContent>
        <Loader loading={loading}>
          {/* {promo.id && (
              <Promotion
                onOpen={this.openPromotion}
                onClickButton={this.clickButton}
                border={true}
                action_button_name={promo.action_button_name}
                description={promo.description}
                image_url={promo.image_url}
                name={promo.name}
                action_url={promo.action_url}
                date_from={promo.date_from}
                date_till={promo.date_till}
                full
              ></Promotion>
            )} */}
          <Promotion
            full
            border={true}
            image_url={promo.image_url}
            action_url={promo.action_url}
            onClickButton={this.clickButton}
          ></Promotion>
          <div className={classNames.title}>{promo.name}</div>
          <div className={classNames.info}>
            <div className={classNames.description}>{promo.description}</div>
            {promo.action_button_name && (
              <Button
                onClick={this.onOpen}
                title={promo.action_button_name}
              />
            )}
          </div>
        </Loader>
      </PageContent>
    );
  }
}
export default PromotionPage;
