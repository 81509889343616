import { rejects } from "assert";
import { resolve } from "dns";
import { request } from "./fetch";

const getCatalogs = () => {
  return new Promise((resolve, reject) => {
    request
      .get("catalogs")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getFilters = () => {
  return new Promise((resolve, reject) => {
    request
      .get("filters")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getBrands = () => {
  return new Promise((resolve, reject) => {
    request
      .get("brands")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPromotions = () => {
  return new Promise((resolve, reject) => {
    request
      .get("promotions")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPromotion = (id: Number) => {
  return new Promise((resolve, reject) => {
    request
      .get("promotions/" + id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getRegions = () => {
  return new Promise((resolve, reject) => {
    request
      .get("regions")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getStores = (
  type = false,
  page = 1,
  region_id = "",
  latitude?: number,
  longitude?: number
) => {
  return new Promise((resolve, reject) => {
    let url = "stores";

    if (!type) {
      url += "?page=" + page;
      // if (region_id.length != 0) url += "&region_id=" + region_id;
      if (latitude && latitude != 0) url += "&latitude=" + latitude;
      if (longitude && longitude != 0) url += "&longitude=" + longitude;
    }
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPays = () => {
  return new Promise((resolve, reject) => {
    request
      .get("payment_types")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getAssorts = (params: any) => {
  return new Promise((resolve, reject) => {
    let url = "assortments";
    // if (catalog_id != "all") url = url + "?catalog_id=" + catalog_id;

    request
      .post(url, params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getProduct = (id: Number) => {
  return new Promise((resolve, reject) => {
    request
      .get("assortments/" + id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getBaskets = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("baskets", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const createOrder = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("orders", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPopularAssorts = (page?: number, price?: string) => {
  return new Promise((resolve, reject) => {
    let url = "assortment/popular?page=" + (page || 1);
    if (price && price?.length > 0) url += "&price=" + price;
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getTapeAssorts = (page?: number) => {
  return new Promise((resolve, reject) => {
    // let url = "assortment/tape?page=" + (page || 1);
    let url = "assortment/tape";
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const createFeedback = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("feedback", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getSearch = (search: string) => {
  return new Promise((resolve, reject) => {
    let url = "search?search=" + search;
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const pay = () => {
  return new Promise((resolve, reject) => {
    let url = "payments/pay";
    request
      .post(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

export {
  getCatalogs,
  getFilters,
  getBrands,
  getPromotions,
  getRegions,
  getStores,
  getPays,
  getAssorts,
  getProduct,
  getPromotion,
  getBaskets,
  createOrder,
  getPopularAssorts,
  getTapeAssorts,
  createFeedback,
  getSearch,
  pay,
};
