import React from "react";
import classNames from "./style.module.scss";

interface Props {
  onOpenShops: any;
  visible: boolean;
  store: any;
}

const Availability = ({ onOpenShops, visible, store, ...props }: Props) => {
  return (
    <div className={classNames.availability}>
      {store.quantity == 0 ? (
        <div className={classNames.availability_info}>
          <div className={classNames.availability_open}>
            Данный товар отсутствует в выбранном магазине
          </div>
        </div>
      ) : (
        <div className={classNames.availability_info}>
          <div className={classNames.availability_title}>
            В наличии
          </div>
          <div>{store.quantity} шт</div>
        </div>
      )}
      {/* <div className={classNames.availability_info}>
        <div>{store.address ? store.address : ""}</div>
        
      </div> */}
    </div>
  );
};

export default Availability;
