import React, { Component } from 'react';
import { Button, PageContent } from '../ui';
import { RouteComponentProps } from "react-router-dom";

export default class EmptyPage extends Component<RouteComponentProps<any>, any> {

    onGoBack = () => {
        this.props.history.replace('/');
    }

    render() {
        return (
            <PageContent style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
                <div style={{textAlign: 'center'}}>Данной страницы не существует</div>
                <Button title={'На главную'} onClick={this.onGoBack}/>
            </PageContent>
        )
    }
}