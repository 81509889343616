import React from "react";
import classNames from "./style.module.scss";

interface Props {
  id: string;
  price: number;
  price_discount?: number;
  name: string;
  img?: any;
  count: number;
  addCount: any;
  subCount: any;
  deleteProduct: any;
  openProduct?: any;
  quantity?: any;
  quantity_count?: number;
  is_discount?: number;
}

interface State {
  openPopconfirm: boolean;
  addClass: boolean;
}
class BasketItem extends React.Component<Props> {
  state: State = { openPopconfirm: false, addClass: false };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let delete_block = document.getElementsByClassName("delete_block")[0];
    let popconfirm = document.getElementsByClassName("popconfirm")[0];
    // console.log(event.path.includes(delete_block));
    // console.log(event.path.includes(popconfirm));
    if (!event.path?.includes(delete_block) && !event.path?.includes(popconfirm))
      this.setState({ openPopconfirm: false });
  };
  subCountProduct = () => {
    if (this.props.count == 1) {
      this.setState({ openPopconfirm: true });
    } else {
      this.props.subCount(this.props.id);
    }
  };

  openDeleteProduct = () => {
    this.setState({ openPopconfirm: true });
  };

  deleteProduct = () => {
    this.setState({ openPopconfirm: false });
    this.props.deleteProduct(this.props.id);
  };

  render() {
    let {
      name,
      price_discount,
      price,
      count,
      id,
      addCount,
      subCount,
      deleteProduct,
      quantity,
      quantity_count,
      img,
      is_discount,
    } = this.props;
    let { openPopconfirm, addClass } = this.state;
    // console.log(quantity_count,count)
    // if (!quantity_count) quantity_count = 0;
    return (
      <div
        className={
          classNames.basket_item + " " + (quantity ? classNames.disabled : "")
        }
      >
        <div className={classNames.flex}>
          <div
            className={classNames.img}
            onClick={this.props.openProduct}
            style={{ backgroundImage: img ? "url(" + img + ")" : "" }}
          ></div>
          <div className={classNames.info}>
            <div
              className={classNames.item_title}
              onClick={this.props.openProduct}
            >
              {name}
            </div>
            <div className={classNames.block_bottom}>
              <div className={classNames.flex}>
                <div className={classNames.price}>
                  {is_discount == 1 ? price_discount : price}₽
                </div>
                {is_discount == 1 && (
                  <div className={classNames.previous_price}>{price} ₽</div>
                )}
              </div>
              <div className={classNames.flex}>
                <div
                  className={"delete" + (addClass ? " delete_block" : "")}
                  onClick={() => this.setState({ openPopconfirm: true })}
                  onMouseOver={() => {
                    this.setState({ addClass: true });
                  }}
                  onMouseOut={() => {
                    this.setState({ addClass: false });
                  }}
                ></div>
                {openPopconfirm && (
                  <div
                    className={classNames.popconfirm + " popconfirm"}
                    // onMouseOut={() => this.setState({ openPopconfirm: false })}
                  >
                    <div className={classNames.title}>
                      Удалить товар из корзины?
                    </div>
                    <div className={classNames.flex}>
                      <div
                        className={classNames.button_yes}
                        onClick={this.deleteProduct}
                      >
                        Да
                      </div>
                      <div
                        className={classNames.button_No}
                        onClick={() => this.setState({ openPopconfirm: false })}
                      >
                        Нет
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    classNames.block_count +
                    " " +
                    (quantity_count && quantity_count < count
                      ? classNames.deficiency
                      : "")
                  }
                >
                  <div
                    className={classNames.operator_minus}
                    onClick={() => this.subCountProduct()}
                  ></div>
                  <div
                    className={
                      quantity_count && quantity_count < count
                        ? classNames.deficiency
                        : ""
                    }
                  >
                    {count}
                  </div>
                  <div
                    className={classNames.operator_add}
                    onClick={() => addCount(id)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {quantity_count && quantity_count < count ? (
          <div className={classNames.limit}>
            Для заказа в выбранном магазине доступно {quantity_count} шт.
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default BasketItem;
