import React, { Component } from "react";
import classNames from "./style.module.scss";
import { PageContent, Button, PhotoBrower } from "../../ui";
import { RouteComponentProps } from "react-router-dom";

interface State {}

class PaySuccessPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {};

  componentDidMount() {
    localStorage.setItem("basket_penny", JSON.stringify([]));
  }
  // componentDidUpdate(prevProps: any) {}

  render() {
    let {} = this.state;

    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
          <div className={classNames.title}>
            Ваш заказ успешно оформлен
          </div>
          <div>
          Более подробная информация отправлена на указанный электронный адрес.
          </div>
          <Button
            title="Вернуться к покупкам"
            onClick={()=>this.props.history.replace("/")}
          ></Button>
        {/* </div> */}
      </PageContent>
    );
  }
}
export default PaySuccessPage;
