import React, { Component } from "react";

import classNames from "./style.module.scss";
import { Loader, PageContent } from "../../ui";
import { Product, Promotion } from "../../features";
import { RouteComponentProps } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import * as request from "../../requests";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination]);

// const qs = require("query-string");

interface State {
  promotions: Array<any>;
  popularProducts: Array<any>;
  loading: boolean;
  products: Array<any>;
}

class RootPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    promotions: [],
    popularProducts: [],
    loading: true,
    products: [],
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const payment = urlParams.get("payment");
    console.log(payment);
    if (payment) this.props.history.replace("/payments/success");
    else {
      request.getPromotions().then((json: any) => {
        this.setState({ promotions: json.data.data });
      });
      request.getPopularAssorts().then((json: any) => {
        let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
        let products = json.data.data;
        products.map((item: any) => {
          let index = basket.findIndex(
            (basketItem: any) => basketItem.id == item.id
          );
          let newProduct = item;
          newProduct.count = index != -1 ? basket[index].count : 0;
          newProduct.img = "";
          if (item.contents)
            item.contents.forEach((element: any) => {
              if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
          return newProduct;
        });
        this.setState({ popularProducts: products }, () => {
          let params = {
            page: 1,
            region_id: localStorage.getItem("City_id") || "",
          };
          request.getTapeAssorts(1).then((json: any) => {
            let basket = JSON.parse(
              localStorage.getItem("basket_penny") || "[]"
            );
            let products = json.data.data;

            products.map((item: any) => {
              let index = basket.findIndex(
                (basketItem: any) => basketItem.id == item.id
              );
              let newProduct = item;
              newProduct.count = index != -1 ? basket[index].count : 0;
              newProduct.img = "";
              item.contents.forEach((element: any) => {
                if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                  newProduct.img = element.url;
                }
              });
              return newProduct;
            });

            this.setState({ products, loading: false });
          });
        });
      });
    }
  }

  openProduct = (id: number) => {
    this.props.history.push("/product/" + id);
  };

  openProducts = (id: string) => {
    this.props.history.push("/products/" + id);
  };

  openPromotion = (id: number) => {
    this.props.history.push("/promotion/" + id);
  };

  addCountPopular = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count += 1;
    let popularProducts = this.state.popularProducts;
    popularProducts[indexState].count += 1;

    let products = this.state.products;
    let newIndex = products.findIndex((item: any) => item.id == id);
    if (newIndex != -1) products[newIndex].count += 1;

    this.setState({ popularProducts, products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };
  subCountProductPopular = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count -= 1;
    if (basket[index].count == 0) {
      basket.splice(index, 1);
    }
    let popularProducts = this.state.popularProducts;
    popularProducts[indexState].count -= 1;

    let products = this.state.products;
    let newIndex = products.findIndex((item: any) => item.id == id);
    if (newIndex != -1) products[newIndex].count -= 1;

    this.setState({ popularProducts, products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };

  addCount = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count += 1;
    let products = this.state.products;
    products[indexState].count += 1;

    let popularProducts = this.state.popularProducts;
    let newIndex = popularProducts.findIndex((item: any) => item.id == id);
    if (newIndex != -1) popularProducts[newIndex].count += 1;

    this.setState({ popularProducts, products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };
  subCountProduct = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count -= 1;
    if (basket[index].count == 0) {
      basket.splice(index, 1);
    }
    let products = this.state.products;
    products[indexState].count -= 1;

    let popularProducts = this.state.popularProducts;
    let newIndex = popularProducts.findIndex((item: any) => item.id == id);
    if (newIndex != -1) popularProducts[newIndex].count -= 1;

    this.setState({ popularProducts, products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };

  addItemPopular = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    let popularProducts = this.state.popularProducts;
    if (index == -1) {
      basket.push({
        id: id,
        count: 1,
      });
    } else {
      basket[index].count += 1;
    }
    let products = this.state.products;
    let newIndex = products.findIndex((item: any) => item.id == id);
    if (newIndex != -1) products[newIndex].count += 1;

    popularProducts[indexState].count += 1;
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.setState({ popularProducts, products });
  };

  addItem = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    let products = this.state.products;
    if (index == -1) {
      basket.push({
        id: id,
        count: 1,
      });
    } else {
      basket[index].count += 1;
    }
    products[indexState].count += 1;

    let popularProducts = this.state.popularProducts;
    let newIndex = popularProducts.findIndex((item: any) => item.id == id);
    if (newIndex != -1) popularProducts[newIndex].count += 1;

    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.setState({ products, popularProducts });
  };
  onClickButton = (action_url: string) => {
    window.location.href = action_url;
  };

  render() {
    let { popularProducts, promotions, loading, products } = this.state;
    return (
      <PageContent>
        <Loader loading={loading}>

          <div className={classNames.slider_top}>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              // onSwiper={(swiper) => console.log(swiper)}
              navigation
              pagination={{ clickable: true }}
            >
              {promotions.map((item) => {
                return (
                  <SwiperSlide>
                    <Promotion
                      onOpen={() => this.openPromotion(item.id)}
                      action_button_name={item.action_button_name}
                      image_url={item.image_url}
                      action_url={item.action_url}
                      onClickButton={() =>
                        this.onClickButton(item.action_url)
                      }
                      withButton={item.action_button_name ? true : false}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div
            className={classNames.title}
            onClick={() => this.openProducts("popular")}
          >
            Популярные товары
            </div>
          <div className={classNames.products}>
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              breakpoints={{
                1200: {
                  slidesPerView: 5,
                },
                900: {
                  slidesPerView: 4,
                },
                500: {
                  slidesPerView: 3,
                },
                350: {
                  slidesPerView: 2,
                },
              }}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              navigation
            // pagination
            >
              {popularProducts.map((product: any, index: number) => {
                return (
                  <SwiperSlide>
                    <Product
                      id={product.id}
                      img={product.img}
                      price={product.price}
                      price_discount={product.price_discount}
                      description={product.name}
                      openProduct={this.openProduct}
                      countBasket={product.count}
                      is_discount={product.is_discount}
                      subCountProduct={() =>
                        this.subCountProductPopular(product.id, index)
                      }
                      addCount={() => this.addCountPopular(product.id, index)}
                      addItem={() => this.addItemPopular(product.id, index)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div
            className={classNames.title}
            onClick={() => this.openProducts("tape")}
          >
            Лента товаров
            </div>
          <div className={classNames.products}>
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              breakpoints={{
                1200: {
                  slidesPerView: 5,
                },
                900: {
                  slidesPerView: 4,
                },
                500: {
                  slidesPerView: 3,
                },
                350: {
                  slidesPerView: 2,
                },
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              navigation
            // pagination
            >
              {products.map((product: any, index: number) => {
                return (
                  <SwiperSlide>
                    <Product
                      id={product.id}
                      img={product.img}
                      price={product.price}
                      price_discount={product.price_discount}
                      description={product.name}
                      openProduct={this.openProduct}
                      countBasket={product.count}
                      is_discount={product.is_discount}
                      subCountProduct={() =>
                        this.subCountProduct(product.id, index)
                      }
                      addCount={() => this.addCount(product.id, index)}
                      addItem={() => this.addItem(product.id, index)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Loader>
      </PageContent>
    );
  }
}

export default RootPage;
