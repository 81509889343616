import React from "react";
import classNames from "./style.module.scss";

interface Props {
  openProduct: any;
  id: string;
  price: number;
  price_discount: number;
  description: string;
  img?: string;
  countBasket?: number;
  subCountProduct: any;
  addCount: any;
  addItem: any;
  withMarginRight?: Boolean;
  is_discount: number;
}

const Product = ({
  description,
  id,
  price_discount,
  price,
  openProduct,
  countBasket,
  addCount,
  subCountProduct,
  addItem,
  withMarginRight,
  img,
  is_discount,
  ...props
}: Props) => {
  return (
    <div
      className={classNames.item_product}
      style={withMarginRight ? { marginLeft: 0, marginRight: 24 } : {}}
    >
      <div
        className={classNames.item_img}
        onClick={() => openProduct(id)}
        style={{ backgroundImage: img ? "url(" + img + ")" : "" }}
      ></div>
      <div className={classNames.block_price}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classNames.price}>
            {is_discount == 1 ? price_discount : price}₽
          </div>
          {is_discount == 1 && (
            <div className={classNames.previous_price}>{price} ₽</div>
          )}
        </div>
        {countBasket ? (
          <div className={classNames.block_count}>
            <div
              className={classNames.operator_minus}
              onClick={() => subCountProduct()}
            ></div>
            <div>{countBasket}</div>
            <div
              className={classNames.operator_add}
              onClick={() => addCount()}
            ></div>
          </div>
        ) : (
          <div className={classNames.add} onClick={() => addItem()}></div>
        )}
      </div>
      <div className={classNames.description} onClick={() => openProduct(id)}>
        {description}
      </div>
    </div>
  );
};

export default Product;
