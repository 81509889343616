import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Availability, СharacteristicItem } from "../../features";
import { PageContent, Button, PhotoBrower, Loader } from "../../ui";
import { RouteComponentProps } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import * as request from "../../requests";
import sortBy from 'lodash/sortBy'
import { EventEmitter } from "../../utils/helpers";

interface State {
  openShops: boolean;
  listCharacteristics: any;
  openPhoto: boolean;
  photos: any;
  photo: number;
  product: any;
  loading: boolean;
  history: Array<any>;
  quantity: Array<any>;
  video: string;
  shop: any;
}
SwiperCore.use([Navigation, Pagination]);

class ProductItemPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    openShops: false,
    listCharacteristics: {
      visibleDescription: false,
      visibleCharacteristic: false,
      visibleVideo: false,
      visibleApplication: false,
      visibleContraindications: false,
      visibleComposition: false,
    },
    openPhoto: false,
    photos: [],
    photo: 0,
    product: {},
    loading: true,
    history: [],
    quantity: [],
    video: "",
    shop: {}
  };

  componentDidMount() {
    EventEmitter.execute('scrollTo', { top: 0 });
    this.fetchProduct();
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.match.params.id != prevProps.match.params.id) {
      this.setState(
        {
          photo: 0,
          product: {},
          loading: true,
          history: [],
          quantity: [],
          openShops: false,
          photos: []
        }, this.fetchProduct
      );
    }
  }

  fetchProduct = () => {
    request.getProduct(this.props.match.params.id).then((json: any) => {
      let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
      let index = basket.findIndex(
        (item: any) => item.id == this.props.match.params.id
      );
      let product = json.data;

      let video = "";
      let photoRow: Array<any> = [];
      if (product.contents) {
        product.contents.forEach((element: any) => {
          if (element.type == "reference" || element.type == 'image') {
            photoRow.push(element);
          } else if (element.type == "video") {
            video = element.url;
          }
        });
      }

      photoRow = sortBy(photoRow, (el: any) => el.main === 0);
      let photos = photoRow.map((el: any) => el.url);

      let history = this.getParents(json.data.catalog, []);
      product.count = index == -1 ? 0 : basket[index].count;
      let shop: any = JSON.parse(localStorage.getItem("shop_penny") || "null");
      if (shop!=null) {
        let shopIndex = json.data.quantity.findIndex(
          (item: any) => item.id == shop.id
        );
        if (shopIndex != -1)
          shop.quantity = json.data.quantity[shopIndex].quantity;
        else shop.quantity = 0;
      }
      else{
        shop={
          quantity:0
        }
      }

      this.setState({
        product,
        loading: false,
        quantity: json.data.quantity,
        history,
        photos,
        shop,
        video
      });
    });
  }

  getParents = (object: any, history: Array<any>) => {
    // console.log(object);
    if (object.parent) history = this.getParents(object.parent, history);
    history.push({
      title: object.name,
      id: object.id,
    });
    return history;
  };

  onAdd = () => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((n: any) => n.id == this.state.product.id);
    // console.log("\\\\", basket);
    // console.log("\\\\", this.state.product.id);
    // console.log("\\\\", basket[index].id);
    if (index == -1)
      basket.push({
        id: this.state.product.id,
        count: 1,
      });
    else {
      basket[index].count += 1;
    }
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    let product = this.state.product;
    product.count++;
    this.setState({ product });
  };

  openAvailability = () => {
    this.setState({ openShops: !this.state.openShops });
  };

  openItem = (field: any) => {
    let listCharacteristics = this.state.listCharacteristics;
    listCharacteristics[field] = !listCharacteristics[field];
    this.setState({ listCharacteristics });
  };

  openPhoto = (index: number) => {
    this.setState({ openPhoto: true, photo: index });
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("hide_scroll"); //
  };

  closePhoto = () => {
    this.setState({ openPhoto: false });
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("hide_scroll");
  };

  subCountProduct = () => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((n: any) => n.id == this.state.product.id);
    // console.log("\\\\", basket);
    // console.log("\\\\", this.state.product.id);
    // console.log("\\\\", basket[index].id);

    if (index != -1) {
      let product = this.state.product;
      basket[index].count -= 1;
      if (basket[index].count <= 0) {
        basket.splice(index, 1);
        product.count = 1;
      }
      localStorage.setItem("basket_penny", JSON.stringify(basket));
      product.count--;
      this.setState({ product });
    }
  };

  openCategory = (id: string) => {
    this.props.history.push("/products/" + id);
  };

  render() {
    let {
      openShops,
      listCharacteristics,
      openPhoto,
      photo,
      photos,
      product,
      loading,
      history,
      quantity,
      shop,
      video
    } = this.state;
    let {
      visibleDescription,
      visibleCharacteristic,
      visibleVideo,
      visibleApplication,
      visibleContraindications,
      visibleComposition,
    } = listCharacteristics;
    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
        <div className={classNames.way}>
          <span onClick={() => this.openCategory("all")}>Все товары / </span>
          {history.map((item, index) => {
            return (
              <span onClick={() => this.openCategory(item.id)}>
                {item.title + (index != history.length - 1 ? " / " : "")}
              </span>
            );
          })}
        </div>
        <Loader loading={loading}>
          <div className={classNames.block_info}>
            <div className={classNames.block_1}>
              <div className={classNames.title}>{product.name}</div>
              <div className={classNames.inventory_number}>
                {product.code}
              </div>
              <Availability
                onOpenShops={this.openAvailability}
                visible={openShops}
                store={shop}
              />
              <div className={classNames.characteristics}>
                {
                  product.description != "" &&
                  <СharacteristicItem
                    title={"Описание"}
                    openItem={() => this.openItem("visibleDescription")}
                    visible={visibleDescription}
                  >
                    {product.description}
                  </СharacteristicItem>
                }
                {
                  product.properties &&
                  <СharacteristicItem
                    title={"Характеристики"}
                    openItem={() => this.openItem("visibleCharacteristic")}
                    visible={visibleCharacteristic}
                  >
                    {product.properties.map((item: any) => {
                      return (
                        <div className={classNames.line}>
                          <div className={classNames.field}>
                            {item.property.name}
                          </div>
                          <div className={classNames.value}>
                            {item.value}
                          </div>
                        </div>
                      );
                    })}
                  </СharacteristicItem>
                }
                {
                  video &&
                  <СharacteristicItem
                    title={"Видео продукта"}
                    openItem={() => this.openItem("visibleVideo")}
                    visible={visibleVideo}
                  >
                    <iframe
                      src={video}
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                      title="video"
                      width="100%"
                      height="250px"
                    />
                  </СharacteristicItem>
                }

                {/* <СharacteristicItem
                  title={"Способ применения"}
                  openItem={() => this.openItem("visibleApplication")}
                  visible={visibleApplication}
                >
                  <div>Отсутствует.</div>
                </СharacteristicItem>

                <СharacteristicItem
                  title={"Противопоказания"}
                  openItem={() => this.openItem("visibleContraindications")}
                  visible={visibleContraindications}
                >
                  <div>Отсутствует.</div>
                </СharacteristicItem> */}

                {
                  product.composition != "" &&
                  <СharacteristicItem
                    title={"Состав"}
                    openItem={() => this.openItem("visibleComposition")}
                    visible={visibleComposition}
                  >
                    {product.composition}
                  </СharacteristicItem>
                }
              </div>
            </div>
            <div className={classNames.block_2}>
              {/* <div
                className={classNames.img_block}
                onClick={this.openPhoto}
              ></div> */}
              {photos.length > 0 ? (
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  // navigation
                  pagination={{ clickable: true }}
                  tag="section"
                  id="main"
                >
                  {photos.map((item: any, index: any) => {
                    return (
                      <SwiperSlide>
                        <div
                          className={classNames.img_block}
                          onClick={() => this.openPhoto(index)}
                          style={{
                            backgroundImage: "url(" + item + ")",
                          }}
                        ></div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                  <div className={classNames.default}></div>
                )}
              <div className={classNames.block_price}>
                <div className={classNames.price}>
                  {product.is_discount == 1
                    ? product.price_discount
                    : product.price}{" "}
                    ₽
                  </div>
                {product.is_discount == 1 && (
                  <div className={classNames.previous_price}>
                    {product.price} ₽
                  </div>
                )}
              </div>
              {product.count == 0 ? (
                <Button
                  onClick={this.onAdd}
                  title={"Добавить в корзину"}
                  add={true}
                  style={{
                    fontSize: 12,
                    padding: "12px 24px",
                    marginTop: 12,
                  }}
                />
              ) : (
                  <div className={classNames.block_count}>
                    <div
                      className={classNames.operator_minus}
                      onClick={() => this.subCountProduct()}
                    ></div>
                    <div>{product.count}</div>
                    <div
                      className={classNames.operator_add}
                      onClick={() => this.onAdd()}
                    ></div>
                  </div>
                )}
            </div>
          </div>
        </Loader>
        {/* </div> */}
        {openPhoto && (
          <PhotoBrower
            closePhoto={this.closePhoto}
            photos={photos}
            photo={photo}
          />
        )}
      </PageContent>
    );
  }
}
export default ProductItemPage;
