import qs from 'qs';

//парсит строку гет параметров в объект
export const getQuery = (query) => {
    return qs.parse(query, { ignoreQueryPrefix: true });
}

//собирает строку из объекта параметров
export const setQuery = (params, noPrefix) => {
    return qs.stringify(params, { addQueryPrefix: !noPrefix });
}

export const EventEmitter = {
    events: {},
    execute: function (event, data) {
        if (!this.events[event]) return;
        this.events[event].forEach(callback => callback(data));
    },
    subscribe: function (event, callback) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback)
    },
    delete: function (event) {
        delete this.events[event]
    }
}

