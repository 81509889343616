import React from "react";
import classNames from "./style.module.scss";

interface Props {
  title: String;
  onclick: any;
  checked?: boolean;
  disabled?: boolean;
}

const RadioButton = ({ title, onclick, checked, disabled }: Props) => {
  return (
    <label
      className={
        classNames.radio_label +
        " " +
        (disabled ? classNames.disabled_radio : "")
      }
      onClick={disabled ? () => {} : onclick}
    >
      <input type="radio" checked={checked} disabled={disabled}></input>
      <div className={classNames.radio}>
        <div className={classNames.point} />
      </div>
      {title}
    </label>
    // <div className="form_radio">
    //   <input id="radio-1" type="radio" name="radio" value="1" checked />
    //   <label>{title}</label>
    // </div>
  );
};

export default RadioButton;
