import React, { CSSProperties } from 'react';
import classNames from './style.module.scss';

interface ILoader {
    loading?: boolean;
    children?: React.ReactNode;
    style?: CSSProperties;
}

const Loader = ({ loading = true, children, style }: ILoader) => {
    return (
        <React.Fragment>
            {
                loading === true ?
                    <div className={classNames.container} style={style}>
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    :
                    children
            }
        </React.Fragment>
    )
}

export default Loader;