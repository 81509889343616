import React from "react";
import classNames from "./style.module.scss";
import { CheckBox } from "../../../ui/CheckBox";
interface Props {
  open: boolean;
  items?: Array<any>;
  scrollBottom?: any;
  clickItem?: any;
  title: string;
  changeReference: any;
  clickCheckbox: any;
  selectedItems: Array<number>;
}

const FilterItemList = ({
  open,
  items,
  scrollBottom,
  clickItem,
  title,
  clickCheckbox,
  selectedItems,
}: Props) => {
  let scrollItems = (event: any) => {
    let bottom =
      event.target.scrollTop + event.target.offsetHeight ==
      event.target.scrollHeight;

    if (bottom) {
      scrollBottom();
    }
    //остановилась на выборе нескольких вариантах чекбоксы
  };

  return (
    <div className={classNames.container}>
      <div className={classNames.block_item} onClick={clickItem}>
        <div className={classNames.title}>{title}</div>
        <div className={open ? classNames.minus : classNames.plus}></div>
      </div>
      <div
        className={open ? classNames.block_subitems : classNames.block_hide}
        onScroll={scrollItems}
      >
        {items?.map((item, index) => {
          return (
            <CheckBox
              key={item.id + '_checkbox_filter_' + index}
              checked={selectedItems.indexOf(item.id) != -1}
              onClick={() => clickCheckbox(item.id)}
              title={item.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterItemList;
