import React, { createRef } from "react";
import classNames from "./style.module.scss";
import { render } from "@testing-library/react";
import * as request from "../../../requests";

interface Props {
  onClickSearch: any;

  value: string;
  onBlur: any;
  openCategory: any;
  openProduct: any;
}

interface State {
  assortments: Array<any>;
  brands: Array<any>;
  catalogs: Array<any>;
  search: string;
  focus: boolean;
}

class Searchbar extends React.Component<Props> {
  private timer: any;
  state: State = {
    assortments: [],
    brands: [],
    catalogs: [],
    search: "",
    focus: false,
  };

  public myRef = createRef<HTMLInputElement>();

  componentDidMount() {
    request.getSearch("").then((json: any) => {
      this.setState({
        assortments: json.assortments,
        brands: json.brands,
        catalogs: json.catalogs,
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let search = document.getElementsByClassName("searchbar_container")[0];
    if (!event.path?.includes(search) && this.state.focus)
      this.onBlur()
  };


  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(this.timer);
    let value = event.currentTarget.value;
    this.setState({ search: value }, () => {
      this.timer = setTimeout(() => {
        request.getSearch(value).then((json: any) => {
          this.setState({
            assortments: json.assortments,
            brands: json.brands,
            catalogs: json.catalogs,
          });
        });
      }, 1000);
    });
  };
  onBlur = () => {
    this.props.onBlur();
    this.setState({ focus: false });
  };
  onFocus = () => {
    this.setState({ focus: true });
  };

  openCategory = (id: string) => {
    this.props.openCategory(id);
    this.onBlur();
  };

  openProduct = (id: string) => {
    this.props.openProduct(id);
    this.onBlur();
  };
  openAllProducts=(id:string)=>{
    this.props.openCategory("",id);
    this.onBlur();
  }
  render() {
    let { onClickSearch, value } = this.props;
    let { assortments, catalogs, brands, focus } = this.state;

    return (
      <div className={classNames.container}>
        <div className={classNames.searchbar_container+ " searchbar_container"}>
          <input
            placeholder={"Что вы ищите?"}
            onChange={this.onChange}
            className={classNames.searchbar}
            ref={(ref) => this.myRef}
            // onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
          <button
            // onClick={onClickSearch}
            value={value}
            className={classNames.button}
          >
            Поиск
          </button>
          {focus && (
            <div className={classNames.list_items}>
              <div className={classNames.block_type}>
                <div className={classNames.title}>Бренды</div>
                {brands.map((item: any) => {
                  return <div className={classNames.item} onClick={()=>this.openAllProducts(item.id)}>{item.name}</div>;
                })}
              </div>
              <div className={classNames.block_type}>
                <div className={classNames.title}>Разделы</div>
                {catalogs.map((item: any) => {
                  return (
                    <div
                      className={classNames.item}
                      onClick={() => this.openCategory(item.id)}
                    >
                      {" "}
                      {item.name}
                    </div>
                  );
                })}
              </div>

              <div className={classNames.block_type}>
                <div className={classNames.title}>Товары</div>
                {assortments.map((item: any) => {
                  return (
                    <div
                      className={classNames.item}
                      onClick={() => this.openProduct(item.id)}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Searchbar;
