import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  RootPage,
  EmptyPage,
  ProductsPage,
  PromotionsPage,
  PromotionPage,
  ProductItemPage,
  FeedbackPage,
  DeliveryPage,
  PaySuccessPage,
  PolicyPage,
} from "../pages";

const MainRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={RootPage} />
      <Redirect exact from="/product" to="/" />
      <Route exact path="/product/:id" component={ProductItemPage} />
      <Route exact path="/products/:id" component={ProductsPage} />
      <Route exact path="/products/:id/:brand" component={ProductsPage} />
      <Route exact path="/products" component={ProductsPage} />
      <Route exact path="/promotions" component={PromotionsPage} />
      <Route exact path="/promotion/:id" component={PromotionPage} />
      <Route exact path="/feedback" component={FeedbackPage} />
      <Route exact path="/delivery" component={DeliveryPage} />
      <Route exact path="/payments/success" component={PaySuccessPage} />
      <Route exact path="/policy" component={PolicyPage} />

      <Route component={EmptyPage} />
    </Switch>
  );
};

export default MainRoute;
