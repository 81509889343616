import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Button } from "../../ui";
import throttle from 'lodash/throttle';

interface Props {
  border?: boolean;
  onOpen?: any;
  action_button_name?: string;
  action_url: string;
  image_url: string;
  onClickButton: any;
  full?: boolean;

  withButton?: boolean;
}

class Promotion extends Component<Props> {
  state = {
    height: 0
  }

  containerRef: HTMLDivElement | null = null;

  componentDidMount = () => {
    if(this.props.full){
      this.setState({ height: this.containerRef!.clientWidth / 3 });
    }else{
      this.setState({ height: this.containerRef!.clientWidth / 3.8 });
    }
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if(this.props.full){
      this.setState({ height: this.containerRef!.clientWidth / 3 });
    }else{
      this.setState({ height: this.containerRef!.clientWidth / 3.8 });
    }
  }


  handleClickButton = (e:any) => {
    const {onClickButton} = this.props;

    e.stopPropagation();

    if(onClickButton){
      onClickButton();
    }
  }

  render() {
    let {
      border,
      action_button_name,
      image_url,
      full,
      onOpen,
      withButton
    } = this.props;
    const { height } = this.state;
    return (
      <div ref={node => this.containerRef = node} style={{ height: height }} className={classNames.item_promotion + " " + (border && classNames.item_border)} onClick={onOpen}>
        <div className={classNames.container} style={full ? { width: '100%' } : {}}>
          <div
            className={classNames.img_block}
            style={{ backgroundImage: "url(" + image_url + ")" }}
          />
          {withButton === true &&
            <div className={classNames.button_container}>
              <Button
                onClick={this.handleClickButton}
                title={action_button_name}
                add={false}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Promotion;
