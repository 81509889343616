import React, { ReactNode, useEffect } from 'react';
import { EventEmitter } from '../../utils/helpers';
// import classNames from './style.module.scss';

interface Props {
    children: ReactNode;
}

const Page = ({ children }: Props) => {
    let containerRef: HTMLDivElement | null = null;

    const handleScroll = (event: Event) => {
        EventEmitter.execute('onScroll');
    }

    const scrollTo = (options: ScrollToOptions) => {
        containerRef?.scrollTo(options);
    }

    useEffect(() => {
        const removeListener = () => {
            containerRef?.removeEventListener('scroll', handleScroll);
            EventEmitter.delete('scrollTo');
        }
        containerRef?.addEventListener('scroll', handleScroll);
        EventEmitter.subscribe('scrollTo', scrollTo);

        return removeListener;
    });

    return (
        <div ref={node => containerRef = node} className={'container_page'}>
            {children}
        </div>
    )
}

export default Page;