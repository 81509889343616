import React from "react";
import classNames from "./style.module.scss";
import BasketItem from "./BasketItem";
import * as request from "../../../requests";
import { Button, Loader } from "../../../ui";

interface Props {
  onClick: any;
  onCreate: any;
  openProduct: any;
}

interface State {
  loading: boolean;
  products: Array<any>;
  basket: Array<any>;
  totalSum: number;
  totalCount: number;
}

class Basket extends React.Component<Props> {
  state: State = {
    loading: true,
    products: [],
    basket: [],
    totalSum: 0,
    totalCount: 0,
  };

  componentDidMount() {
    // localStorage.setItem("basket_penny", JSON.stringify([]));
    this.getBasket();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let basket = document.getElementsByClassName("basket")[0];
    if (!event.path?.includes(basket)) this.props.onClick();
  };

  getBasket = () => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    // console.log("basket---------", basket);
    this.setState({ basket });

    let params = {
      assortments: basket.map((item: any) => item.id),
    };

    if (basket.length != 0)
      request
        .getBaskets(params)
        .then((json: any) => {
          let products = json.data;
          let totalSum = 0;
          let totalCount = 0;
          products.map((item: any, index: number) => {
            let newProduct = item;
            let newIndex = basket.findIndex((n: any) => n.id == item.id);
            newProduct.count = basket[newIndex].count;
            totalSum +=
              newProduct.count *
              (newProduct.is_discount == 1
                ? newProduct.price_discount
                : newProduct.price);
            totalCount += newProduct.count;
            newProduct.img = "";
            if (item.contents)
              item.contents.forEach((element: any) => {
                if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                  newProduct.img = element.url;
                }
              });
            return newProduct;
          });
          this.setState({
            products,
            totalSum: totalSum.toFixed(2),
            totalCount,
          });
        })
        .finally(() => this.setState({ loading: false }));
    else this.setState({ loading: false });
  };

  addCount = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);

    totalSum =
      totalSum -
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) *
      products[index].count;
    totalCount += 1;
    basket[index].count += 1;
    products[index].count += 1;
    totalSum =
      totalSum +
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) *
      products[index].count;
    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };

  subCount = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);
    totalSum -=
      products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price;
    totalCount -= 1;
    basket[index].count -= 1;
    products[index].count -= 1;
    if (products[index].count == 0) {
      products.splice(index, 1);
      basket.splice(index, 1);
    }
    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };

  deleteProduct = (id: string) => {
    let { basket, products, totalCount, totalSum } = this.state;
    let index = products.findIndex((item: any) => item.id == id);
    totalSum -=
      (products[index].is_discount == 1
        ? products[index].price_discount
        : products[index].price) * products[index].count;
    totalCount -= products[index].count;

    products.splice(index, 1);
    basket.splice(index, 1);

    this.setState({
      basket,
      products,
      totalCount,
      totalSum: totalSum.toFixed(2),
    });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };

  render() {
    let { loading, products, totalSum, totalCount } = this.state;
    return (
      <div className={classNames.container}>
        <div className="basket">
          <div className={classNames.container_busket}>
            <div>
              <div className={classNames.flex}>
                <div
                  className={classNames.close}
                  onClick={this.props.onClick}
                ></div>
                <div className={classNames.title}>Корзина</div>
              </div>
              <Loader
                loading={loading}
              >
                {products.length == 0 ? (
                  <div className="empty_block">Корзина пуста</div>
                ) : (
                    <div className={classNames.basket_items}>
                      {products.map((item: any) => {
                        return (
                          <div className="basket_item">
                            <BasketItem
                              id={item.id}
                              img={item.img}
                              price={item.price}
                              name={item.name}
                              price_discount={item.price_discount}
                              count={item.count}
                              addCount={this.addCount}
                              subCount={this.subCount}
                              is_discount={item.is_discount}
                              deleteProduct={this.deleteProduct}
                              openProduct={() => this.props.openProduct(item.id)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </Loader>
            </div>
            <div className={classNames.result}>
              <div className={classNames.result_block}>
                <div className={classNames.title_bottom}>Итого</div>
                <div>
                  <div className={classNames.price_block}>{totalSum} ₽</div>
                  <div className={classNames.count}>
                    {" "}
                    {totalCount +
                      (totalCount % 10 == 1 && totalCount != 11
                        ? " товар"
                        : totalCount % 10 > 1 &&
                          totalCount % 10 < 5 &&
                          (totalCount > 20 || totalCount < 5)
                          ? " товара"
                          : " товаров")}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  onClick={
                    totalCount == 0 ? () => { } : () => this.props.onCreate()
                  }
                  title={"Оформить заказ"}
                  // add={false}
                  style={{ width: '100%' }}
                  disabled={totalCount == 0 ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Basket;
