import React, { Component } from "react";

import classNames from "./style.module.scss";
import { Loader, Page, PageContent } from "../../ui";
import { Product, FilterButton } from "../../features";
import { RouteComponentProps } from "react-router-dom";
import * as request from "../../requests";
import { observer } from "mobx-react";
import { appStore } from "../../stores";
import { Thumbs } from "swiper";
import { EventEmitter } from "../../utils/helpers";
interface State {
  active: boolean;
  id: string;
  products: Array<any>;
  loader: boolean;
  history: Array<any>;
  title_category: string;
  filters: Array<any>;
  loading: boolean;
  page: number;
  last_page: number;
  categories: Array<any>;
  filterFlag: boolean;
  applyFilters: Array<any>;
  sort: string;
}
interface Props { }

// @inject("appStore")
// @observer

class ProductsPage extends React.Component<Props & RouteComponentProps<any>, State> {
  state: State = {
    id: "",
    products: [],
    active: false,
    loader: false,
    history: [],
    title_category: "",
    filters: [],
    loading: false,
    page: 1,
    last_page: 1,
    categories: [],
    filterFlag: true,
    applyFilters: [],
    sort: "",
  };


  containerRef: HTMLDivElement | null = null;

  componentDidMount = () => {
    EventEmitter.subscribe('onScroll', this.handleScroll);

    EventEmitter.execute('scrollTo', { top: 0 });

    let categories_penny = JSON.parse(
      localStorage.getItem("categories_penny") || "[]"
    );
    if (this.props.match.params.id != "all" && this.props.match.params.id != "tape" && this.props.match.params.id != "popular") {
      let index = categories_penny.findIndex((item: any) => item.id == this.props.match.params.id);
      let parent_index = -1;
      let categories = [];

      console.log(index);
      if (index === -1) {
        console.log('Категория отсутствует.');
        this.props.history.replace('/products/all');
        return;
      }

      if (categories_penny[index].parent_id) {
        parent_index = categories_penny.findIndex(
          (item: any) => item.id == categories_penny[index].parent_id
        );
        categories = categories_penny[parent_index].childs;
      }

      this.setState({ categories });
    } else {
      this.setState({ categories: [] });
    }


    // let history = JSON.parse(localStorage.getItem("history_catalog") || "{}");
    // this.containerRef?.addEventListener("scroll", this.handleScroll);
    if (appStore.products.length != 0 && !this.props.match.params.brand) {
      let index = appStore.products.findIndex(
        (item: any) => item.id == this.props.match.params.id
      );
      if (index != -1) {
        this.setState({
          id: this.props.match.params.id,
          loader: false,
          products: appStore.products[index].items,
          filters: appStore.products[index].filters,
          history: appStore.products[index].history,
          title_category: appStore.products[index].title_category,
        });
      } else {
        this.setState({ id: this.props.match.params.id, loader: true }, () => {
          // console.log(321)
          this.getAssorts([]);
        });
      }
    } else {
      // console.log(123);
      this.setState({ id: this.props.match.params.id, loader: true }, () => {
        this.getAssorts([]);
      });
    }
  }

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.match.params.id != prevProps.match.params.id ||
      this.props.match.params.brand != prevProps.match.params.brand
    ) {

      EventEmitter.execute('scrollTo', { top: 0 });

      if (
        this.props.match.params.id != "all" &&
        this.props.match.params.id != "tape" &&
        this.props.match.params.id != "popular"
      ) {
        let categories_penny = JSON.parse(
          localStorage.getItem("categories_penny") || "[]"
        );
        let indexCategory = categories_penny.findIndex(
          (item: any) => item.id == this.props.match.params.id
        );
        let parent_index = -1;
        let categories = [];

        console.log(indexCategory);
        if (indexCategory === -1) {
          console.log('Категория отсутствует.');
          this.props.history.replace('/products/all');
          return;
        }

        if (categories_penny[indexCategory].parent_id) {
          parent_index = categories_penny.findIndex(
            (item: any) => item.id == categories_penny[indexCategory].parent_id
          );
          categories = categories_penny[parent_index].childs;
        }

        this.setState({ categories });
      } else {
        this.setState({ categories: [] });
      }


      let newproducts = appStore.products;
      let index = newproducts.findIndex(
        (item: any) => item.id == this.props.match.params.id
      );

      if (index != -1 && !this.props.match.params.brand) {
        this.setState(
          { loader: true, loading: false, products: [], filterFlag: true },
          () => {
            setTimeout(() => {
              this.setState({
                id: this.props.match.params.id,
                loader: false,
                products: appStore.products[index].items,
                filters: appStore.products[index].filters,
                history: appStore.products[index].history,
                title_category: appStore.products[index].title_category,
                page: appStore.products[index].page,
                last_page: appStore.products[index].last_page,
                filterFlag: false,
              });
            }, 200);
          }
        );
      } else {
        this.setState(
          {
            id: this.props.match.params.id,
            loader: true,
            page: 1,
            last_page: 1,
            filters: [],
            products: [],
            filterFlag: true,
          },
          () => {
            // console.log(456)
            this.getAssorts([]);
          }
        );
      }
    }
  }

  componentWillUnmount = () => {
    EventEmitter.delete('onScroll');
  }

  handleScroll = (event: any) => {
    let { page, last_page, applyFilters, loading } = this.state;
    let diff = this.containerRef!.getBoundingClientRect().height + this.containerRef!.getBoundingClientRect().y;

    if (diff < 900 && loading == false && (page <= last_page)) {
      this.setState({ loading: true }, () => {
        this.getAssorts(applyFilters);
      });
    }
  };

  getAssorts = (filters?: Array<any>) => {
    // console.log("__________", filters);
    let { page, last_page, applyFilters } = this.state;
    let params: any;
    if (
      (filters?.length != 0 && filters != applyFilters) ||
      (filters.length == 0 && applyFilters.length != 0)
    )
      page = 1;
    // console.log("-----", page);
    if (page <= last_page) {
      let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
      let cachesShop = JSON.parse(localStorage.getItem("shop_penny") || '{}');

      if (this.props.match.params.id == "popular") {
        let price = "";
        filters?.forEach((item) => {
          if (item.property == "price") {
            price = item.value;
          }
        });
        request.getPopularAssorts(page, price).then((json: any) => {
          let products = [];
          if (page > 1) products = this.state.products.concat(json.data.data);
          else products = json.data.data;

          products.map((item: any) => {
            let index = basket.findIndex(
              (basketItem: any) => basketItem.id == item.id
            );
            let newProduct = item;
            newProduct.count = index != -1 ? basket[index].count : 0;
            newProduct.img = "";
            item.contents.forEach((element: any) => {
              if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
            return newProduct;
          });
          page = page + 1;
          this.setState({
            products,
            loader: false,
            page,
            last_page: json.data.last_page,
            loading: false,
            filterFlag: false,
          });
        });
      } else if (this.props.match.params.id == "tape") {
        request.getTapeAssorts(page).then((json: any) => {
          let products = this.state.products.concat(json.data.data);

          products.map((item: any) => {
            let index = basket.findIndex(
              (basketItem: any) => basketItem.id == item.id
            );
            let newProduct = item;
            newProduct.count = index != -1 ? basket[index].count : 0;
            newProduct.img = "";
            item.contents.forEach((element: any) => {
              if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
            return newProduct;
          });
          page = page + 1;
          this.setState({
            products,
            loader: false,
            page,
            last_page: json.data.last_page,
            loading: false,
            filterFlag: false,
          });
        });
      } else {
        // console.log("__________", filters);
        if (this.props.match.params.id != "all" && this.props.match.params.id) {
          params = {
            page: page,
            catalog:
              (this.props.match.params.id != "all" &&
                this.props.match.params.id) ||
              "",
            in_stock: cachesShop.id,
          };
        } else {
          params = {
            page: page,
            in_stock: cachesShop.id,
          };
        }
        if (filters?.length != 0) {
          let newFilter: Array<any>;
          newFilter = [];
          filters?.forEach((item) => {
            if (item.property == "price") {
              params.price = item.value;
            } else if (item.property == "brands") {
              let values = "";
              item.value.forEach((brand: string) => {
                values += brand + ";";
              });
              values = values.substring(0, values.length - 1);
              params.brand = values;
            } else newFilter.push(item);
          });
          params.properties = newFilter.length != 0 ? newFilter : [];
        }
        if (this.state.sort.length != 0) params.sort_price = this.state.sort;
        if (this.props.match.params.brand)
          params.brand = this.props.match.params.brand;
        request.getAssorts(params).then((json: any) => {
          let products = [];
          if (page != 1) products = this.state.products.concat(json.data.data);
          else products = json.data.data;
          products.map((item: any) => {
            let index = basket.findIndex(
              (basketItem: any) => basketItem.id == item.id
            );
            let newProduct = item;
            newProduct.count = index != -1 ? basket[index].count : 0;
            newProduct.img = "";
            item.contents.forEach((element: any) => {
              if ((element.type == "reference" || element.type == "image") && element.main == 1 && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
            return newProduct;
          });
          let history = [];
          if (products.length != 0) history = this.getParents(json.catalog, []);

          let newproducts = appStore.products;
          let index = newproducts.findIndex(
            (item: any) => item.id == this.props.match.params.id
          );
          if (index != -1) {
            newproducts[index].items = newproducts[index].items.concat(
              products
            );
            newproducts[index].page = page + 1;
          } else if (!this.props.match.params.brand) {
            newproducts.push({
              id: this.props.match.params.id,
              items: products,
              filters: json.filters,
              history: history,
              title_category: json.catalog.name,
              last_page: json.data.last_page,
              page: page + 1,
            });
          }
          appStore.setProducts(newproducts);
          page = page + 1;
          this.setState({
            products,
            loader: false,
            history,
            title_category: json.catalog.name,
            filters: json.filters,
            page,
            last_page: json.data.last_page,
            loading: false,
            filterFlag: false,
          });
        });
      }
    }
  };

  getParents = (object: any, history: Array<any>) => {
    // console.log(object);
    if (object.parent) history = this.getParents(object.parent, history);
    history.push({
      title: object.name,
      id: object.id,
    });
    return history;
  };

  openProduct = (id: string) => {
    // console.log(id);
    this.props.history.push("/product/" + id);
  };

  onclickFilter = () => {
    this.setState({ active: !this.state.active });
  };

  addCount = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count += 1;
    let products = this.state.products;
    products[indexState].count += 1;
    this.setState({ products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };
  subCountProduct = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    basket[index].count -= 1;
    if (basket[index].count == 0) {
      basket.splice(index, 1);
    }
    let products = this.state.products;
    products[indexState].count -= 1;
    this.setState({ products });
    localStorage.setItem("basket_penny", JSON.stringify(basket));
  };
  addItem = (id: string, indexState: number) => {
    let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
    let index = basket.findIndex((item: any) => item.id == id);
    let products = this.state.products;
    if (index == -1) {
      basket.push({
        id: id,
        count: 1,
      });
    } else {
      basket[index].count += 1;
    }
    products[indexState].count += 1;
    localStorage.setItem("basket_penny", JSON.stringify(basket));
    this.setState({ products });
  };

  closeFilter = () => {
    this.setState({ active: false });
  };

  openCategory = (id: string) => {
    this.props.history.push("/products/" + id);
  };

  scrollBottom = () => {
    // console.log(123321);
  };

  applyFilter = (filters: Array<any>) => {
    // console.log("+++++++++++++", filters);
    this.setState({ loader: true, page: 1, applyFilters: filters }, () => {
      this.getAssorts(filters);
    });
  };

  changeSort = (value: string) => {
    this.setState({ sort: value, loader: true, page: 1 }, () =>
      this.getAssorts([])
    );
  };

  render() {
    let {
      active,
      id,
      products,
      loader,
      history,
      title_category,
      filters,
      categories,
      filterFlag,
      sort,
      loading
    } = this.state;
    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
        {id != "all" &&
          history.length > 0 &&
          this.props.match.params.id &&
          // !loader &&
          products.length != 0 && (
            <div className={classNames.way}>
              <span onClick={() => this.openCategory("all")}>
                Все товары /{" "}
              </span>
              {history.map((item, index) => {
                return (
                  <span onClick={() => this.openCategory(item.id)}>
                    {item.title + (index != history.length - 1 ? " / " : "")}
                  </span>
                );
              })}
            </div>
          )}

        <div className={classNames.block_top}>
          <div className={classNames.block_top_left}>
            {/* {products.length != 0 && ( */}
            <div className={classNames.title}>
              <div
                className={classNames.arrow_title}
                onClick={() => this.props.history.goBack()}
              ></div>
              {id == "all" || !this.props.match.params.id
                ? "Все товары"
                : id == "popular"
                  ? "Популярные товары"
                  : id == 'tape'
                    ? 'Лента товаров'
                    : title_category}
            </div>
            {/* )} */}
            <div className={classNames.block_sort}>
              <span className={classNames.sort}>Сортировка по:</span>
              {/* <span className={classNames.sort_item_active}>
                  популярности
                </span> */}
              <span
                className={
                  sort == "asc"
                    ? classNames.sort_item_active
                    : classNames.sort_item
                }
                onClick={() => this.changeSort("asc")}
              >
                дешевле
                </span>
              <span
                className={
                  sort == "desc"
                    ? classNames.sort_item_active
                    : classNames.sort_item
                }
                onClick={() => this.changeSort("desc")}
              >
                дороже
                </span>
            </div>
          </div>
          {!filterFlag && (
            <FilterButton
              onClickFilter={this.onclickFilter}
              active={active}
              closeFilter={this.closeFilter}
              filters={filters}
              applyFilter={this.applyFilter}
            />
          )}
        </div>

        <div ref={node => this.containerRef = node} className={classNames.block_main}>
          <Loader loading={loader}>
            {categories.length != 0 && (
              <div className={classNames.block_left}>
                {categories.map((item) => {
                  return (
                    <div onClick={() => this.openCategory(item.id)}>
                      {item.name}
                    </div>
                  );
                })}
              </div>
            )}
            {products.length != 0 ? (
              <div className={classNames.block_products}>
                {products.map((item: any, index: number) => {
                  return (
                    <Product
                      key={item.assortment_id + '_product_' + index}
                      img={item.img}
                      id={item.assortment_id}
                      price={item.price}
                      price_discount={item.price_discount}
                      description={item.name}
                      openProduct={() => this.openProduct(item.id)}
                      countBasket={item.count}
                      subCountProduct={() =>
                        this.subCountProduct(item.id, index)
                      }
                      addCount={() => this.addCount(item.id, index)}
                      addItem={() => this.addItem(item.id, index)}
                      withMarginRight={true}
                      is_discount={item.is_discount}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={classNames.empty}>Список товаро пуст</div>
            )}
            {
              loading === true &&
              <div className={classNames.loader}>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            }
          </Loader>

        </div>
        {/* </div> */}
      </PageContent>
    );
  }
}
export default observer(ProductsPage);
