import React, { createRef } from "react";
import classNames from "./style.module.scss";
import { MapView } from "../../MapView";
import { Button } from "../../../ui";
import * as request from "../../../requests";
import { withYMaps, YMaps, YMapsApi } from "react-yandex-maps";

interface Props {
  onClose: any;
  choiceAddress?: any;
  noClose?: boolean;
}

interface State {
  openSearch: boolean;
  stores: Array<any>;
  selectedAddress: any;
  page: number;
  last_page: number;
  lat: number;
  lng: number;
  client_address: string;
}

class MapModal extends React.Component<Props> {
  public myRef = createRef<HTMLDivElement>();
  public selectedRef = createRef<HTMLDivElement>();
  private timer: any;
  state: State = {
    openSearch: false,
    stores: [],
    selectedAddress: "",
    page: 2,
    last_page: 2,
    lat: 0,
    lng: 0,
    client_address: "",
  };

  // const MapModal = ({ ...props }: Props) => {
  componentDidMount() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.setState(
    //       {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       },
    //       () => {
    //         let { lat, lng } = this.state;
    //         request
    //           .getStores(this.state.page, "", lat, lng)
    //           .then((json: any) => {
    //             this.setState({
    //               stores: json.data.data,
    //               last_page: json.data.last_page,
    //             });
    //           });
    //       }
    //     );
    //   });
    // } else

    let shop: any = JSON.parse(localStorage.getItem("shop_penny") || "{}");
    console.log("*_*_*_*_*", shop.address);
    setTimeout(() => {
      if (shop.address) this.setState({ selectedAddress: shop });

    }, 2000);

    request.getStores(true).then((json: any) => {
      setTimeout(() => {
        this.setState({
          stores: json.data,
          // selectedAddress: shop,
        });
      }, 1000);
    });
  }

  openSearch = () => {
    this.setState({ openSearch: !this.state.openSearch });
  };

  choiceAddress = (address: any) => {
    // localStorage.setItem("shop_penny", JSON.stringify(address));

    this.setState({ selectedAddress: address });
  };
  scrollStores = (event: any) => {
    // let scrollBottom =
    //   event.target.scrollTop + event.target.offsetHeight ==
    //   event.target.scrollHeight;
    // if (scrollBottom) {
    //   this.getStores();
    // }
  };

  getStores = () => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.setState({
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     });
    //   });
    // }
    let type = true;
    let { lat, lng } = this.state;
    if (this.state.page <= this.state.last_page)
      request
        .getStores(type, this.state.page, "", lat, lng)
        .then((json: any) => {
          let newStores = json.data.data;
          let stores = this.state.stores.concat(newStores);
          this.setState({
            stores,
            page: this.state.page + 1,
          });
        });
  };

  clickMarker = (id: string) => {
    let index = this.state.stores.findIndex((item) => item.id == id);
    this.setState({ selectedAddress: this.state.stores[index] }, () =>
    // ({ behavior: 'smooth', top: myRef.current.offsetTop })
    // (0, this.selectedRef.current?.offsetTop || 0)
    {
      let locations = document.getElementsByClassName("items_locations")[0];

      console.log(this.selectedRef);
      locations.scrollTo({
        behavior: "smooth",
        top: (this.selectedRef.current?.offsetTop || 150) - 150,
      });
      this.myRef.current?.scrollTo({
        behavior: "smooth",
        top: (this.selectedRef.current?.offsetTop || 150) - 150,
      });
    }
    );
  };

  setAddress = (address: string) => {
    this.setState({ client_address: address });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(this.timer);
    let value = event.currentTarget.value;
    this.setState({ client_address: value }, () => {
      this.timer = setTimeout(() => {
        fetch(
          "https://geocode-maps.yandex.ru/1.x? geocode=" +
          value +
          "&apikey=p33ad0f99-e990-4eca-8dda-aafe2e3e6d64"
        )
          .then((response) => response.json())
          .then((json) => { });
      }, 1000);
    });
  };

  render() {
    let { openSearch, stores, selectedAddress, client_address } = this.state;
    const { noClose } = this.props;
    // console.log("_*_*_*_*_", selectedAddress);
    return (
      <div className={classNames.container} style={this.props.noClose ? { backgroundColor: 'rgba(0,0,0,.5)' } : {}}>
        <div className={classNames.modal}>
          {!noClose && <MapView
            onChangeAddress={this.setAddress}
            // lat={address.lat}
            clickMarker={this.clickMarker}
            markers={stores}
            selectedAddress={selectedAddress}
          />}
          {!this.props.noClose && (
            <div
              className={classNames.close}
              onClick={this.props.onClose}
            ></div>
          )}
          <div
            className={
              openSearch
                ? classNames.container_search
                : (noClose ? classNames.block_locations_center : classNames.block_locations)
            }
          >
            <div className={classNames.title} style={noClose ? {textAlign: 'center'} : {}}>
              {this.props.noClose
                ? "Выберите магазин"
                : "Выберите магазин"}
            </div>

            {openSearch ? (
              <div className={classNames.block_search}>
                <div className={classNames.block_container_search}>
                  <input
                    value={client_address}
                    onChange={this.changeInput}
                  ></input>
                  <img
                    className={classNames.icon}
                    src={require(`../../../assets/images/search-icon-black.svg`)}
                  />
                </div>
              </div>
            ) : (
                <div
                  className={classNames.items + " items_locations"}
                  onScroll={this.scrollStores}
                  ref={this.myRef}
                >
                  {stores.length > 0 &&
                    stores.map((item) => {
                      return (
                        <div
                          className={
                            classNames.item +
                            " " +
                            (selectedAddress.id == item.id
                              ? classNames.selectedAddress
                              : "")
                          }
                          ref={
                            selectedAddress.id == item.id ? this.selectedRef : ""
                          }
                          onClick={() => this.choiceAddress(item)}
                        >
                          {item.address || ""}
                        </div>
                      );
                    })}
                </div>
              )}
            <div
              className={
                classNames.bottom_block +
                " " +
                (openSearch && classNames.button_search)
              }
            >
              {this.props.choiceAddress && (
                <Button
                  title={openSearch ? "Выбрать адрес" : "Выбрать магазин"}
                  add={false}
                  style={{ margin: 0, padding: "12px 40px", fontSize: 16 }}
                  onClick={() =>
                    this.props.choiceAddress(
                      openSearch ? "client" : "shop",
                      openSearch ? client_address : selectedAddress
                    )
                  }
                  disabled={
                    openSearch ? client_address == "" : selectedAddress == ""
                  }
                />
              )}
              {/* <div style={{ marginTop: 8 }}>
                <a onClick={this.openSearch}>
                  {openSearch ? "Доставка в магазин" : "Доставка на дом"}
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapModal;
