import { type } from "os";
import React from "react";
import classNames from "./style.module.scss";

interface Props {
  title?: String;
  onClick?: any;
  add?: boolean;
  style?: any;
  disabled?: boolean;
  type?: string;
}

const Button = ({ title, onClick, style, add, disabled, type }: Props) => {
  return (
    <button
      className={
        classNames.button_open +
        " " +
        (disabled ? classNames.disabled : " ") +
        (type ? (type == "red" ? classNames.button_red : "") : "")
      }
      onClick={disabled ? () => {} : onClick}
      style={style}
    >
      {add && <div className={classNames.add}></div>}
      <span>{title}</span>
    </button>
  );
};

export default Button;
