import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Availability, СharacteristicItem } from "../../features";
import { PageContent, Button, PhotoBrower, Loader } from "../../ui";
import { RouteComponentProps } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import ReactNotification from 'react-notifications-component'
// import { store } from 'react-notifications-component';
import * as request from "../../requests";
import { EventEmitter } from "../../utils/helpers";

interface State {
  name: string;
  email: string;
  text: string;
  loading: boolean;
}
SwiperCore.use([Navigation, Pagination]);

class FeedbackPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    name: "",
    email: "",
    text: "",
    loading: false,
  };

  componentDidMount = () => {
    EventEmitter.execute('scrollTo', { top: 0 });
  }
  // componentDidUpdate(prevProps: any) {}

  onSend = () => {
    this.setState({ loading: true }, () => {
      let { name, email, text } = this.state;
      let params = { name, email, text };
      request.createFeedback(params).then((json: any) => {
        // store.addNotification({
        //   title: "Wonderful!",
        //   message: json.msg,
        //   type: "success",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //     onScreen: true
        //   }
        // });
        this.setState({ loading: false }, () => {
          this.props.history.push("/")
        });
      });
    });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let field = event.currentTarget.id;
    let value = event.currentTarget.value;
    if (field == "email") this.setState({ email: value });
    else if (field == "name") this.setState({ name: value });
  };

  changeTextarea = (event: React.FormEvent<HTMLTextAreaElement>) => {
    let value = event.currentTarget.value;
    this.setState({ text: value });
  };

  render() {
    let { name, email, text, loading } = this.state;

    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
        <Loader loading={loading}></Loader>
        <div className={classNames.title}>Форма обратной связи</div>
        <div className={classNames.flex}>
          <div className={classNames.block_left}>
            <div className={classNames.item_information}>
              <div className={classNames.item_title}>Ваше имя</div>
              <input
                id="name"
                value={name}
                onChange={this.changeInput}
              ></input>
            </div>
            <div className={classNames.item_information}>
              <div className={classNames.item_title}>Электронная почта</div>
              <input
                id="email"
                value={email}
                onChange={this.changeInput}
              ></input>
            </div>
            <div className={classNames.item_information}>
              <div className={classNames.item_title}>Текст сообщения</div>
              <textarea
                value={text}
                onChange={this.changeTextarea}
              ></textarea>
            </div>
            <Button onClick={this.onSend} title={"Отправить"}></Button>
          </div>
          <div className={classNames.block_right}>
            <div className={classNames.subtitle}>Напишите нам чтобы:</div>
            <div>• Рассказать о вашем мнении о продукте</div>
            <div>• Предложить интересную идею</div>
            <div>• Посоветовать что-то изменить</div>
            <div>• Или сообщить о наличии проблем</div>
          </div>
        </div>
        {/* </div> */}
        {/* <ReactNotification /> */}
      </PageContent>
    );
  }
}
export default FeedbackPage;
