import React, { ReactNode } from "react";
import classNames from "./style.module.scss";

interface Props {
  title: String;
  children: ReactNode;
  openItem: any;
  visible: boolean;
}

const СharacteristicItem = ({
  title,
  children,
  openItem,
  visible,
  ...props
}: Props) => {
  return (
    <div
      className={
        classNames.characteristic +
        " " +
        (visible && classNames.characteristic_open)
      }
      onClick={openItem}
    >
      <div className={classNames.item}>
        <div className={classNames.title}>{title}</div>
        <div className={classNames.arrow}></div>
      </div>
      {visible && <div className={classNames.info}>{children}</div>}
    </div>
  );
};

export default СharacteristicItem;
