import React, { Component } from "react";
import classNames from "./style.module.scss";
import { CatalogButton, Catalog } from "./Catalog";
import { NavbarButton } from "./NavbarButton";
import { Searchbar } from "./Searchbar";
import { Basket } from "./Basket";
import { Ordering } from "./Ordering";
import { MapModal } from "./MapModal";
import { Cities } from "./Cities";
import * as request from "../../requests";
import { setInterval } from "timers";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../../ui";

interface State {
  searchVisible: boolean;
  results: Array<[]>;
  search: string;
  visible: boolean;
  openItem: number;
  visible_basket: boolean;
  hide_navbar: boolean;
  visible_ordering: boolean;
  visible_shops: boolean;
  visible_map: boolean;
  shop: string;
  basketBadge: boolean;
  cities: Array<any>;
  // autoCity: boolean;
  openAuto: boolean;
}

type PropsType = RouteComponentProps & {};

class Navbar extends React.Component<PropsType> {
  state: State = {
    searchVisible: false,
    results: [],
    search: "",
    visible: false,
    openItem: 0,
    visible_basket: false,
    visible_ordering: false,
    visible_shops: false,
    visible_map: false,
    hide_navbar: false,
    shop: "",
    basketBadge: false,
    cities: [],
    openAuto: true,
  };

  componentDidMount() {
    // console.log('****', localStorage.getItem("shop_penny"))
    if (localStorage.getItem("shop_penny")) {
      let shop: any = JSON.parse(localStorage.getItem("shop_penny") || "{}");
      if (!shop.address) this.setState({ visible_shops: true });
      else this.setState({ shop: shop.address });
    } else this.setState({ visible_shops: true });

    setInterval(() => {
      let basketBadge = this.state.basketBadge;
      let basket = JSON.parse(localStorage.getItem("basket_penny") || "[]");
      if (
        (basket.length > 0 && !basketBadge) ||
        (basket.length == 0 && basketBadge)
      )
        this.setState({ basketBadge: basket.length > 0 ? true : false });
    }, 1000);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let search_block = document.getElementsByClassName("search_container")[0];
    let search_block_mobile = document.getElementsByClassName(
      "searchbar_mobile"
    )[0];
    let auto = document.getElementsByClassName("auto-block")[0];
    if (
      !event.path?.includes(search_block) &&
      !event.path?.includes(search_block_mobile)
    )
      this.setState({ results: [] });
    if (!event.path?.includes(auto) && this.state.openAuto)
      this.setState({ openAuto: false });
  };
  getAddress = (position: any) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
        position.lat +
        "&lon=" +
        position.lng +
        "&zoom=18&addressdetails=1"
      )
        .then((response) => response.json())
        .then((json) => {
          if (json.address.city) {
            let index = this.state.cities.findIndex(
              (item: any) => item.name == json.address.city
            );
            if (index != -1) {
              this.setState({ city: json.address.city, autoCity: true });
            }
            resolve(json);
          }
        })
        .catch((error) => reject(error));
    });
  };

  handleSearchVisible = () => {
    this.setState({ searchVisible: !this.state.searchVisible });
    this.revealScrollBody();
  };

  handleClickSearch = () => {
    this.hideScrollBody();
  };

  onBlurSearch = () => {
    this.revealScrollBody();
  };

  handleClickOpenCatalog = () => {
    // console.log(window.screen.width);
    if (window.screen.width <= 680) {
      this.hideScrollBody();
      this.setState({ hide_navbar: true });
    }
    this.setState({ visible: true });
  };

  handleClickCloseCatalog = () => {
    // console.log('close catalog')
    this.setState({ visible: false });

    if (window.screen.width <= 680) {
      this.revealScrollBody();
      this.setState({ hide_navbar: false });
    }
  };

  handleOpenItem = (id: number) => {
    // console.log(id)
    this.setState({ openItem: id });
  };

  // handleCloseItem = () => {
  //   this.setState({ openItem: 0 });
  // };

  openBasket = () => {
    this.setState({ visible_basket: true });
    this.hideScrollBody();
  };

  closeBasket = () => {
    this.setState({ visible_basket: false });
    this.revealScrollBody();
  };

  openOrdering = () => {
    this.setState({
      visible_ordering: true,
      visible_basket: false,
    });
    this.hideScrollBody();
  };
  closeOrdering = () => {
    this.setState({
      visible_ordering: false,
    });
    this.revealScrollBody();
  };

  onCreateOrder = () => {
    this.closeOrdering();
    this.props.history.replace("/payments/success");
    // localStorage.setItem("basket_penny", JSON.stringify([]));
  };

  openShops = () => {
    this.setState({
      visible_shops: true,
      visible: false,
      openAuto: false,
    });
    this.hideScrollBody();
  };

  closeMap = () => {
    this.setState({
      visible_shops: false,
    });
    this.revealScrollBody();
  };

  hideScrollBody = () => {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("hide_scroll");
    let page = document.getElementsByClassName("container_page")[0];
    if (!page.classList.contains("hide_scroll"))
      page.classList.add("hide_scroll");
  };
  revealScrollBody = () => {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("hide_scroll");
    let page = document.getElementsByClassName("container_page")[0];
    page.classList.remove("hide_scroll");
  };

  openCategory = (id: string, brand?: string) => {
    this.handleClickCloseCatalog();
    this.setState({ searchVisible: false });
    this.revealScrollBody();
    if (id.length != 0) this.props.history.push("/products/" + id);
    else this.props.history.push("/products/all/" + brand);
  };

  openProduct = (id: string) => {
    this.closeOrdering();
    this.closeBasket();
    this.setState({ searchVisible: false });
    this.revealScrollBody();
    this.props.history.push("/product/" + id);
  };

  choiceAddress = (type: string, address: any) => {
    // console.log('----0-0-0',address);
    localStorage.setItem("shop_penny", JSON.stringify(address));
    let shop=address.address;

    setTimeout(() => {
      this.setState({ visible_shops: false, shop });
    }, 200);
  };

  render() {
    let {
      searchVisible,
      search,
      results,
      visible_basket,
      visible_ordering,
      visible_shops,
      shop,
      hide_navbar,
      basketBadge,
    } = this.state;
    return (
      <nav
        className={
          classNames.container +
          " " +
          (visible_basket ? classNames.hide_scroll : "")
        }
      >
        <div className={classNames.top_container}>
          <div className={classNames.left}>
            <div className={classNames.city_title}>Магазин:</div>
            <div className={classNames.city_name} onClick={this.openShops}>
              {shop.substr(0,35)+'...'}
            </div>
          </div>
          <div className={classNames.right}>
            <div className={classNames.addictional_block}>
              <a href="/promotions" className={classNames.link}>
                Акции
              </a>
              <a href="/delivery" className={classNames.link}>
                Доставка и оплата
              </a>
              <a href="/feedback" className={classNames.link}>
                Обратная связь
              </a>
            </div>
            <div className={classNames.tel}>+7 (8652) 230-030</div>
          </div>
        </div>
        {searchVisible === false ? (
          <div className={classNames.navbar}>
            <div className={classNames.left}>
              <a href="/">
                <img
                  className={classNames.logo}
                  src={require("../../assets/images/logo.png")}
                />
              </a>
              <div
                className={classNames.catalog_button_right}
                onMouseOver={this.handleClickOpenCatalog}
                onMouseOut={this.handleClickCloseCatalog}
              >
                <CatalogButton onClickOpen={this.handleClickOpenCatalog} />
                <div className={classNames.catalog_right}>
                  <Catalog
                    visible={this.state.visible}
                    openCity={this.openShops}
                    onCloseCatalog={this.handleClickCloseCatalog}
                    openCategory={this.openCategory}
                  />
                </div>
              </div>

              <div className="search_container">
                <Searchbar
                  onClickSearch={this.handleClickSearch}
                  openCategory={this.openCategory}
                  value={search}
                  openProduct={this.openProduct}
                  onBlur={this.onBlurSearch}
                />
              </div>
            </div>
            {!hide_navbar && (
              <div className={classNames.right}>
                {/* hide if mobile */}
                <div
                  className={classNames.search_button}
                  onClick={this.handleClickSearch}
                >
                  <NavbarButton
                    icon={"search-icon-black.svg"}
                    text={"Поиск"}
                    onClick={this.handleSearchVisible}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  {basketBadge && (
                    <div className={classNames.basket_badge}></div>
                  )}
                  <NavbarButton
                    icon={"cart-icon-black.svg"}
                    text={"Корзина"}
                    onClick={this.openBasket}
                  // onClick={this.openMap} //пока разрабатываются карты
                  />
                </div>
                {visible_basket && (
                  <Basket
                    onClick={this.closeBasket}
                    onCreate={this.openOrdering}
                    openProduct={this.openProduct}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
            <div className={classNames.navbar}>
              <div className="searchbar_mobile">
                <Searchbar
                  onClickSearch={this.handleClickSearch}
                  openCategory={this.openCategory}
                  value={search}
                  openProduct={this.openProduct}
                  onBlur={this.onBlurSearch}
                />
              </div>
              <img
                src={require("../../assets/images/close-black.svg")}
                className={classNames.icon_close}
                onClick={this.handleSearchVisible}
              />
              <div
                className={classNames.fone + " fone"}
                onClick={this.handleSearchVisible}
              ></div>
            </div>
          )}
        {visible_ordering && (
          <Ordering
            onCreate={this.onCreateOrder}
            onClose={this.closeOrdering}
            openProduct={this.openProduct}
          />
        )}
        {visible_shops && (
          <MapModal
            onClose={this.closeMap}
            choiceAddress={this.choiceAddress}
            noClose={shop.length == 0}
          />
        )}

        {/* {visible_map && <MapModal onClose={this.openMap}/>} */}
      </nav>
    );
  }
}

// export default Navbar;
export default withRouter(Navbar);
