import React from "react";
import classNames from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { MapModal } from "../../features/Navbar/MapModal";

type PropsType = RouteComponentProps & {};
interface State {
  visible_map: boolean;
}
class Footer extends React.Component<PropsType> {
  state: State = { visible_map: false };
  openCategory = (id: string) => {
    this.props.history.push("/products/" + id);
  };
  openMap = () => {
    this.setState({ visible_map: true });
  };
  closeMap = () => {
    this.setState({ visible_map: false });
  };
  render() {
    let { visible_map } = this.state;
    return (
      <footer className={classNames.container}>
        <div className={classNames.footer_block} style={{ width: '30%'}}>
          <div className={classNames.footer_title}>Популярное</div>
          <div
            className={classNames.footer_element}
            onClick={() =>
              this.openCategory("2d4916b8-c039-11ea-80d4-ac1f6bc56bfe")
            }
          >
            Бытовая химия
          </div>
          <div
            className={classNames.footer_element}
            onClick={() =>
              this.openCategory("2d4916b6-c039-11ea-80d4-ac1f6bc56bfe")
            }
          >
            Товары для дома{" "}
          </div>
          <div
            className={classNames.footer_element}
            onClick={() =>
              this.openCategory("876ffcb7-c01a-11ea-80d3-ac1f6bc56bff")
            }
          >
            Уход за собой{" "}
          </div>
          <div
            className={classNames.footer_element}
            onClick={() =>
              this.openCategory("2d4916bc-c039-11ea-80d4-ac1f6bc56bfe")
            }
          >
            Товары для детей и мам
          </div>
          <div
            className={classNames.footer_element}
            onClick={() =>
              this.openCategory("2d4916be-c039-11ea-80d4-ac1f6bc56bfe")
            }
          >
            Товары для животных
          </div>
        </div>

        <div className={classNames.footer_block} style={{ width: 120 }}>
          <div className={classNames.footer_title}>Разное</div>
          <div className={classNames.footer_element} onClick={this.openMap}>
            Карта магазинов
          </div>
          <div
            className={classNames.footer_element}
            onClick={() => this.props.history.push("/delivery")}
          >
            Доставка и оплата
          </div>
          <div
            className={classNames.footer_element}
            onClick={() => this.props.history.push("/policy")}
          >
            Политика конфиденциальности
          </div>
          <div
            className={classNames.footer_element}
            onClick={() => this.props.history.push("/feedback")}
          >
            Обратная связь
          </div>
        </div>

        <div className={classNames.links_block}>
          <img
            src={require("../../assets/images/edadil-icon.svg")}
            className={classNames.links_element}
          />
          <img
            src={require("../../assets/images/google-play-icon.svg")}
            className={classNames.links_element}
            onClick={() =>
              (window.location.href = "https://play.google.com/store/apps/details?id=com.edadeal.android&hl=ru&gl=US")
            }
          />
          <img
            src={require("../../assets/images/app-store-icon.svg")}
            className={classNames.links_element}
            onClick={() =>
              (window.location.href = "https://apps.apple.com/ru/app/%D0%B5%D0%B4%D0%B0%D0%B4%D0%B8%D0%BB-%D1%81%D0%BA%D0%B8%D0%B4%D0%BA%D0%B8-%D0%B2-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD%D0%B0%D1%85/id700569948")
            }
          />
        </div>

        <div className={classNames.social_links_block}>
          <div className={classNames.footer_title}>Свяжитесь с нами</div>
          <div className={classNames.social_links}>
            <img
              src={require("../../assets/images/vk-icon.svg")}
              className={classNames.links_element}
              onClick={() => (window.location.href = "https://vk.com/kopdom26")}
            />
            <img
              src={require("../../assets/images/ok-icon.svg")}
              className={classNames.links_element}
              onClick={() =>
                (window.location.href = "https://ok.ru/group/54144032047172")
              }
            />
            <img
              src={require("../../assets/images/insta-icon.svg")}
              className={classNames.links_element}
              onClick={() =>
                (window.location.href = "https://www.instagram.com/kopdom26/")
              }
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <div className={classNames.gray_title}>Телефон и факс</div>
            <div className={classNames.footer_element}>+7 (8652) 230-030</div>
          </div>
          <div style={{ marginBottom: 16 }}>
            <div className={classNames.gray_title}>Адрес</div>
            <div className={classNames.footer_element}>
              355037, Россия, г. Ставрополь, ул. Шпаковская, 97
            </div>
          </div>
        </div>
        {visible_map && (
          <MapModal
            onClose={this.closeMap}
            // choiceAddress={this.choiceAddress}
          />
        )}
      </footer>
    );
  }
}
export default withRouter(Footer);
