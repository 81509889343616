import { observable, action } from "mobx";

class appStore {
  @observable hide_scroll = false;

  @observable products: Array<any> = [];
  @action setProducts = (newProducts: any) => {
    this.products = newProducts;
  };

  @observable categories: Array<any> = [];
  @action setCategories = (newCategories: any) => {
    this.categories = newCategories;
  };

  @observable shops: Array<any> = [];
  @action setShops= (newShops: any) => {
    this.shops = newShops;
  };
}
export default new appStore();
