import React from "react";
import classNames from "./style.module.scss";
import * as request from "../../../requests";
import { FilterItemList } from "../FilterItemList";
import { lchmodSync } from "fs";
interface Props {
  onClickFilter: any;
  active: boolean;
  closeFilter: any;
  filters: Array<any>;
  applyFilter: any;
}
interface State {
  shops: Array<any>;
  shopsLastPage: number;
  shopsCurrentPage: number;
  openFilter: string;
  arrayReference: Array<any>;
  arrayNumeric: Array<any>;
  filters: Array<any>;
}
interface Funcs {
  onClickItem: any;
}

class FilterButton extends React.Component<Props> {
  state: State = {
    shops: [],
    shopsLastPage: 1,
    shopsCurrentPage: 1,
    openFilter: "",
    arrayReference: [],
    arrayNumeric: [],
    filters: [],
  };

  componentDidMount() {
    request
      .getStores(false, 1, localStorage.getItem("City_id") || "")
      .then((json: any) => {
        console.log(json);
        let shops = json.data.data.map((item: any) => {
          let newItem = item;
          newItem.name = item.address;
          return newItem;
        });
        this.setState({
          shops,
          shopsLastPage: json.data.last_page,
          shopsCurrentPage: json.data.current_page,
        });
      })
      .finally(() => {
        let arrayReference = this.state.arrayReference,
          arrayNumeric = this.state.arrayNumeric;

        // arrayReference.push(
        //   {
        //   id: "in_stock",
        //   name: "Наличие в магазинах",
        //   values: this.state.shops,
        //   selectedValues: [],
        //   type: "",
        // }
        // );
        arrayNumeric.push({
          id: "price",
          name: "Цена, ₽",
          min: "",
          max: "",
        });

        this.props.filters.forEach((item) => {
          if (item.property.type_value.view == "reference") {
            let newItem = {
              id:
                item.property.id == "35c94178-b4bc-11ea-80d3-ac1f6bc56bff"
                  ? "brands"
                  : item.property.id,
              name: item.property.name,
              values: [],
              selectedValues: [],
              type: item.property.type_value.type,
            };
            arrayReference.push(newItem);
          } else if (item.property.type_value.view == "numeric") {
            let newItem = {
              id: item.property.id,
              name: item.property.name,
              min: "",
              max: "",
            };
            arrayNumeric.push(newItem);
          }
        });

        this.setState({ arrayReference, arrayNumeric }, () => {
          let index = arrayReference.findIndex((item) => item.id == "brands");
          if (index != -1) this.getBrands(index);
        });
      });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let filter_info = document.getElementsByClassName("block_filter")[0];
    // let block_filter = document.getElementsByClassName("filter-info")[0];
    if (!event.path?.includes(filter_info)) this.props.closeFilter();
  };

  getBrands = (index: number) => {
    request.getBrands().then((json: any) => {
      let arrayReference = this.state.arrayReference;
      arrayReference[index].values = json.data;
      this.setState({ arrayReference });
    });
  };
  scrollShops = () => {
    let { shopsLastPage, shopsCurrentPage } = this.state;
    console.log(shopsLastPage, shopsCurrentPage);
    if (shopsCurrentPage < shopsLastPage) {
      shopsCurrentPage = shopsCurrentPage + 1;
      request
        .getStores(
          false,
          shopsCurrentPage,
          localStorage.getItem("City_id") || ""
        )
        .then((json: any) => {
          let newShops = json.data.data.map((item: any) => {
            let newItem = item;
            newItem.name = item.address;
            return newItem;
          });
          let shops = this.state.shops.concat(newShops);
          let arrayReference = this.state.arrayReference;
          arrayReference[0].values = shops;
          this.setState(
            {
              shops,
              arrayReference,
              shopsLastPage: json.data.last_page,
              shopsCurrentPage: json.data.current_page,
            },
            () => console.log(shops)
          );
        });
    }
  };

  clickOpen = (id: string) => {
    this.setState({ openFilter: this.state.openFilter == id ? "" : id });
  };

  changeNumeric = (value: string, type: string, index: number) => {
    // let value = event.currentTarget.value;
    let arrayNumeric = this.state.arrayNumeric;
    if (type == "min") arrayNumeric[index].min = value;
    else arrayNumeric[index].max = value;
    this.setState(arrayNumeric);
  };
  changeReference = (value: Array<any>, index: number) => {};

  applyClick = () => {
    console.log(this.state);
    let { arrayReference, arrayNumeric } = this.state;
    let filters: Array<any>;
    filters = [];
    arrayNumeric.forEach((item) => {
      filters.push({
        property: item.id,
        value:
          (item.min.length == 0 ? "0" : item.min) +
          ";" +
          (item.max.length == 0 ? "" : item.max),
      });
    });
    arrayReference.forEach((item) => {
      if (item.selectedValues.length != 0) {
        filters.push({
          property: item.id,
          value: item.selectedValues,
        });
      }
    });
    // console.log("----------", filters);
    this.props.applyFilter(filters);
    this.props.closeFilter();
  };
  clickCheckbox = (id: string, index: number) => {
    let arrayReference = this.state.arrayReference;
    let indexItem = arrayReference[index].selectedValues.indexOf(id);
    if (indexItem == -1) arrayReference[index].selectedValues.push(id);
    else arrayReference[index].selectedValues.splice(indexItem, 1);
    this.setState({ arrayReference });
  };

  clickClear = () => {
    let { arrayReference, arrayNumeric } = this.state;
    arrayNumeric = [];
    arrayReference = [];
    // arrayReference.push({
    //   id: "in_stock",
    //   name: "Наличие в магазинах",
    //   values: this.state.shops,
    //   selectedValues: [],
    //   type: "",
    // });
    arrayNumeric.push({
      id: "price",
      name: "Цена, ₽",
      min: "",
      max: "",
    });

    this.props.filters.forEach((item) => {
      if (item.property.type_value.view == "reference") {
        let newItem = {
          id: item.property.id,
          name: item.property.name,
          values: [],
          selectedValues: [],
          type: item.property.type_value.type,
        };
        arrayReference.push(newItem);
      } else if (item.property.type_value.view == "numeric") {
        let newItem = {
          id: item.property.id,
          name: item.property.name,
          min: "",
          max: "",
        };
        arrayNumeric.push(newItem);
      }
    });
    this.setState({ arrayReference, arrayNumeric });
  };
  render() {
    let { active, onClickFilter, filters } = this.props;
    let { shops, openFilter, arrayReference, arrayNumeric } = this.state;
    return (
      <div className={classNames.container + " block_filter"}>
        <div
          className={
            active ? classNames.block_filter_active : classNames.block_filter
          }
          onClick={onClickFilter}
        >
          <div className={classNames.filter_icon}></div>
          <span>Фильтр</span>
        </div>
        <div
          className={
            active ? classNames.filter_info_active : classNames.filter_info
          }
        >
          <div style={{ position: "relative" }}>
            <div style={{ minHeight: 358 }}>
              {arrayNumeric.length != 0 &&
                arrayNumeric.map((item, index) => {
                  return (
                    <div key={item.id + '_filter_button_' + index}>
                      <div className={classNames.title}>{item.name}</div>
                      <div className={classNames.diapason}>
                        <div>От</div>
                        <input
                          value={item.min}
                          className={classNames.diapason_item}
                          type="number"
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>
                          ) =>
                            this.changeNumeric(
                              event.currentTarget.value,
                              "min",
                              index
                            )
                          }
                        ></input>
                        <div>До</div>
                        <input
                          value={item.max}
                          className={classNames.diapason_item}
                          type="number"
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>
                          ) =>
                            this.changeNumeric(
                              event.currentTarget.value,
                              "max",
                              index
                            )
                          }
                        ></input>
                      </div>
                    </div>
                  );
                })}
              {arrayReference.length != 0 &&
                arrayReference.map((item, index) => {
                  return (
                    <FilterItemList
                      key={item.id + '_filter_item_list_' + index}
                      title={item.name}
                      items={item.values}
                      selectedItems={item.selectedValues}
                      open={openFilter == item.id}
                      clickItem={() => this.clickOpen(item.id)}
                      changeReference={(value: Array<any>) =>
                        this.changeReference(value, index)
                      }
                      clickCheckbox={(id: string) =>
                        this.clickCheckbox(id, index)
                      }
                      scrollBottom={this.scrollShops}
                    />
                  );
                })}
            </div>
            <div className={classNames.bottom_block}>
              <div className={classNames.block_gradient}></div>
              <div className={classNames.buttons_block}>
                <div className={classNames.cancel} onClick={this.clickClear}>
                  Очистить
                </div>
                <div onClick={this.applyClick}>Применить</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterButton;
