import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Availability, СharacteristicItem } from "../../features";
import { PageContent, Button, PhotoBrower } from "../../ui";
import { RouteComponentProps } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import ReactNotification from 'react-notifications-component'
// import { store } from 'react-notifications-component';
import { EventEmitter } from "../../utils/helpers";

interface State {}
SwiperCore.use([Navigation, Pagination]);

class PolicyPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {};

  componentDidMount() {
	EventEmitter.execute('scrollTo', { top: 0 });
  }

  render() {
    let {} = this.state;

    return (
      <PageContent>
        {/* <div className={classNames.container}> */}
          <div className={classNames.title}>Политика конфиденциальности и обработки персональных данных при пользовании сайтом www.shop.kopdom.ru</div>
          <div></div>
          <div>В тексте настоящей Политики используются следующие термины:</div>
          <div>Сайт – совокупность логически связанных между собой веб-страниц в информационно-телекоммуникационной сети «Интернет», имеющий доменное имя <a href="https://shop.kopdom.ru">shop.kopdom.ru</a>.</div>
          <div>Администрация Сайта – лица, осуществляющие действия по управлению Сайтом.</div>
          <div>Пользователь Сайта – физическое лицо, пользующееся Сайтом и предоставляющий свои персональные данные.</div>
          <div>
            <ol>
              <li>Общие положения
              	<div></div>
              	<div>1.1. Настоящая Политика конфиденциальности и обработки персональных данных (далее – Политика) действует в отношении всей информации, которую Администрация Сайта может получить о Пользователе во время использования им Сайта <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a>. Согласие Пользователя Сайта на предоставление персональных данных дается им в соответствии с настоящей Политикой в рамках использования Сайта.
              	</div>
              	<div>1.2. Принимая условия настоящей Политики и регистрируясь на Сайте, Пользователь тем самым дает согласие на обработку своих персональных данных. Настоящее согласие субъекта персональных данных действует бессрочно (со дня его подписания в порядке, предусмотренном настоящим пунктом, до дня отзыва) в письменной форме и может быть в любой момент отозвано субъектом персональных данных путем письменного обращения в порядке, предусмотренном п. 6.1.4 настоящей Политики.
              	</div>
              	<div>1.3. Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
              	</div>
              	<div>1.4. Настоящая Политика разработана в соответствии Федеральным законом «О персональных данных» от 27 июля 2006г. № 152-ФЗ и принятых в соответствии с указанным законом нормативных актов.
              	</div>
              	<div>1.5. Настоящая Политика применяется только к Сайту <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a>. Сайт <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a> не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a>.
              	</div>
              	<div></div>
              </li>
              <li>Персональные данные Пользователя
              	<div></div>
              	<div>2.1. В рамках настоящей Политики под персональными данными Пользователя понимаются:
              	</div>
              	<div>Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) на Сайте или в процессе использования сервисов Сайта, включая персональные данные Пользователя:
              		<ul>
              			<li>фамилию, имя, отчество Пользователей и их несовершеннолетних детей;</li>
              			<li>возраст Пользователей и детей Пользователей;</li>
              			<li>адрес места проживания;</li>
              			<li>номера контактных телефонов;</li>
              			<li>адрес электронной почты;</li>
              			<li>адрес доставки заказа;</li>
              			<li>иная информация о Пользователе, предоставление которой происходит при использовании Сайта.</li>
              		</ul>
              	</div>
              	<div>2.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.
              	</div>
              	<div>2.2 Администрация Сайта по общему правилу не проверяет достоверность персональных данных, предоставляемых Пользователями, и не осуществляет контроль за их дееспособностью. Однако Администрация Сайта исходит из того, что Пользователь предоставляет достоверные персональные данные и поддерживает эту информацию в актуальном состоянии.
              	</div>
              	<div></div>
              </li>
              <li>Цели сбора и обработки персональных данных Пользователей
              	<div></div>
              	<div>3.1. Администрация Сайта собирает и хранит только персональные данные, необходимые для предоставления сервисов Сайта или исполнения договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных.
              	</div>
              	<div>3.2. Персональные данные Пользователя Администрация Сайта обрабатывает в следующих целях:
              	</div>
              	<div>3.2.1. Идентификации Пользователя.
              	</div>
              	<div>3.2.2. Предоставления Пользователю доступа к ресурсам Сайта.
              	</div>
              	<div>3.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, заключения договоров, оказания услуг, обработку запросов и заявок Пользователя.
              	</div>
              	<div>3.2.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
              	</div>
              	<div>3.2.5. Создания учетной записи Пользователя.
              	</div>
              	<div>3.2.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.
              	</div>
              	<div>3.2.7. Осуществления рекламной деятельности с согласия Пользователя.
              	</div>
              	<div>3.2.8. Выполнения своих обязательств перед Пользователями, возникающих в связи с использованием Пользователем Сайта;
              	</div>
              	<div>3.2.9. Продвижения Товара.
              	</div>
              	<div>3.2.11. Улучшение сервисов сайта, удобства их использования, разработка новых сервисов и услуг.
              	</div>
              	<div></div>
              </li>
              <li>Условия обработки персональных данных и передачи третьим лицам
              	<div></div>
              	<div>4.1. В отношении персональной информации Пользователя сохраняется конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа всем Пользователям Сайта. При использовании отдельных сервисов Сайта, Пользователь соглашается с тем, что определенная часть его персональных данных становится общедоступной.
              	</div>
              	<div>4.2 Администрация Сайта вправе передавать персональные данные пользователя третьим лицам в следующих случаях:
              	</div>
              	<div>4.2.1 Пользователь явно выразил свое согласие на такие действия;
              	</div>
              	<div>4.2.2 Передача предусмотрена действующим законодательством;
              	</div>
              	<div>4.2.3. Передача персональных данных необходима для исполнения договора или заявки Пользователя в соответствии с ресурсами Сайта.
              	</div>4.3. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.
              	<div>4.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных".
              	</div>
              	<div>4.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных данных.
              	</div>
              	<div>4.6. Администрация Сайта предоставляет доступ к персональным данным Пользователя только тем работникам, третьим лицам, которым эта информация необходима для обеспечения функционирования Сайта и предоставления услуг Пользователю. Администрация Сайта вправе использовать персональные данные в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей).
              	</div>
              	<div>4.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
              	</div>
              	<div></div>
              </li>
              <li>Меры, применяемые для защиты персональных данных
              	<div></div>
              	<div>5.1 Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
              	</div>
              	<div>5.2 Обеспечение безопасности персональных данных достигается следующими мерами:
              	</div>
              	<div>5.2.1 Назначение лица, ответственного за организацию обработки персональных данных;
              	</div>
              	<div>5.2.2 Проведение внутреннего контроля соответствия обработки персональных данных законодательству и нормативным правовым актам, требованиям к защите персональных данных, знаний и выполнения ответственными лицами своих функциональных обязанностей в части защиты персональных данных;
              	</div>
              	<div>5.2.3 Определение списка лиц, допущенных к работе с персональными данными;
              	</div>
              	<div>5.2.4 Разработка и утверждение локальных нормативных актов, регламентирующих порядок обработки персональных данных.
              	</div>
              	<div></div>
              </li>
              <li>Права и обязанности
              	<div></div>
              	<div>6.1. Пользователь имеет право:
              	</div>
              	<div>6.1.1. на получение информации, касающейся обработки его персональных данных, в том числе содержащей:
              		<ul>
              			<li>подтверждение факта обработки персональных данных;
              			</li>
              			<li>правовые основания и цели обработки персональных данных;
              			</li>
              			<li>цели и применяемые Администрацией Сайта способы обработки персональных данных;
              			</li>
              			<li>наименование и место нахождение Администрации Сайта, сведения о лицах (за исключением работников Администрации Сайта), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Администрацией Сайта или на основании федерального закона;
              			</li>
              			<li>обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;
              			</li>
              			<li>сроки обработки персональных данных, в том числе сроки их хранения;
              			</li>
              			<li>порядок осуществления субъектом персональных данных прав, предусмотренных законодательством;
              			</li>
              			<li>иные сведения, предусмотренные законодательством о персональных данных.
              			</li>
              		</ul>
              	</div>
              	<div>6.1.2. в любое время изменить (обновить, уточнить) предоставленную им персональную информацию или ее часть в своем личном кабинете на Сайте либо в порядке, предусмотренном  в п. 6.1.4  настоящей Политики.
              	</div>
              	<div>6.1.3. в любое время обратиться в Администрацию Сайта с заявлением о прекращении обработки персональных данных в порядке, установленном в п. 6.1.4  настоящей Политики.
              	</div>
              	<div>6.1.4. Получить вышеуказанную информацию Пользователь может, обратившись с письменным запросом в Администрацию Сайта, направив его по электронной почте shopruk@kopdom.ru, либо обратившись в магазины, указанные на «Сайте», путем заполнения соответствующей анкеты. Ответ направляется по адресу, указанному в запросе Пользователя, в течение 30 дней.
              	</div>
              	<div>6.2. Пользователь обязан:
              	</div>
              	<div>6.2.1. Предоставить персональную информацию, необходимую для пользования Сайтом.
              	</div>
              	<div>6.2.2. Обновлять, дополнять предоставленную персональную информацию в случае изменения данной информации.
              	</div>
              	<div>6.3. Администрация Сайта обязана:
              	</div>
              	<div>6.3.1. Использовать полученную персональную информацию Пользователя исключительно для целей, указанных в настоящей Политике.
              	</div>
              	<div>6.3.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой.
              	</div>
              	<div>6.3.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.
              	</div>
              	<div>6.3.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.
              	</div>
              	<div></div>
              </li>
              <li>Ответственность Сторон
              	<div></div>
              	<div>7.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.
              	</div>
              	<div>7.2. В случае утраты или разглашения персональной информации Пользователя Администрация Сайта не несет ответственности, если данная информация:
              	</div>
              	<div>7.2.1. стала публичным достоянием до ее утраты или разглашения;
              	</div>
              	<div>7.2.2. была получена от третьей стороны до момента ее получения Администрацией Сайта;
              	</div>
              	<div>7.2.3. была разглашена с согласия Пользователя;
              	</div>
              	<div>7.2.4. была утрачена не по вине Администрации Сайта.
              	</div>
              	<div></div>
              </li>
              <li>Разрешение споров
              	<div></div>
              	<div>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией Сайта, обязательным является предъявление претензии.
              	</div>
              	<div>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
              	</div>
              	<div>8.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.
              	</div>
              	<div></div>
              </li>
              <li>Прочие  условия
              	<div></div>
              	<div>9.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
              	</div>
              	<div>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте.
              	</div>
              	<div>9.3. Действующая Политика конфиденциальности размещена на странице по адресу: <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a>
              	</div>
              	<div>9.4. Настоящая Политика конфиденциальности является неотъемлемой частью Пользовательского соглашения, размещенного на странице по адресу: <a href="https://shop.kopdom.ru">www.shop.kopdom.ru</a>.
              	</div>
              	<div>9.5. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее законодательство Российской Федерации.
              	</div>
              </li>
            </ol>
          </div>
        {/* </div> */}
      </PageContent>
    );
  }
}
export default PolicyPage;
