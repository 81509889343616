import React, { ReactNode } from 'react';
import classNames from './style.module.scss';

interface Props {
    children: ReactNode;
    style?: Object;
    scrollBottom?:any;
}

const PageContent = ({ children, style = {}, scrollBottom }: Props) => {
    return (
        <main className={classNames.container} style={style} onScroll={scrollBottom}>
            {children}
        </main>
    )
}

export default PageContent;