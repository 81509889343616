import React from 'react';
import classNames from './style.module.scss';
interface Props {
    onClickOpen: any;
}
const CatalogButton = ({ onClickOpen }: Props) => {
    return (
        <button className={classNames.container} onClick={onClickOpen}>
            <img src={require('../../../../assets/images/catalog-icon.svg')} className={classNames.icon}/>
            <img src={require('../../../../assets/images/catalog-icon-black.svg')} className={classNames.icon_mobile}/>
            <span className={classNames.text}>Каталог</span>
        </button>
    )
}

export default CatalogButton;