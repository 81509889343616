import React, { Component, PropTypes } from 'react';
import L from 'leaflet';
// import MarkerClusterGroup from 'react-leaflet-markercluster';
// import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import moment from 'moment';
// import { YMaps } from 'react-yandex-maps';
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";

const key = 'p33ad0f99-e990-4eca-8dda-aafe2e3e6d64 ';

// const mapbox_types = [
//     'streets-v9',
//     'satellite-streets-v9',
//     'light-v9',
//     'dark-v9',
//     'outdoors-v9'
// ]

// const mapbox_link = 'https://api.mapbox.com/styles/v1/mapbox/' + mapbox_types[0] + '/tiles/{z}/{x}/{y}?access_token=' + key_mapbox;

const mapData = {
    center: [45.01628985039873, 41.92791178822518],
    zoom: 10,
};

const coordinates = [
    [55.684758, 37.738521],
    [57.684758, 39.738521]
];


export default class MapView extends Component {
    state = {
        count: 0,
        client_address: ""
    }

    componentDidMount() {
        // this.setState({ count: this.props.markers.length })
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                mapData.center = [position.coords.latitude, position.coords.longitude];
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedAddress != this.props.selectedAddress) {
            if (this.props.selectedAddress.latitude) {
                let lat = this.props.selectedAddress.latitude.replace(',', '.');
                let lng = this.props.selectedAddress.longitude.replace(',', '.');
                if (this.map) this.map.setCenter([lat, lng], 15)
                let balloonContent = '<div class="title">'
                    + this.props.selectedAddress.name + '</div>' +
                    '<div class="flex">' +
                    '<div class="block_info_address"><div class="sub_title">' +
                    'Время работы' +
                    '</div>' +
                    '<div class="value">' + "Пн-Пт " +
                    moment(this.props.selectedAddress.timeopen, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(this.props.selectedAddress.timeclose, 'HH:mm:ss').format('HH:mm') +
                    '</div>' +
                    '</div>' +
                    '<div class="block_info_address"><div class="sub_title">' +
                    'Телефон' +
                    '</div>' +
                    '<div class="value">' +
                    this.props.selectedAddress.phone +
                    '</div>' +
                    '</div>' +
                    '</div>'
                if (this.map) this.map.balloon.open([lat, lng], balloonContent);

            }
        }
    }


    clickMarker = (lat, lng, id) => {
        console.log(lat, lng, id)
        if (this.map) this.map.setCenter([lat, lng], 15)
        this.props.clickMarker(id)
    }

    getAddress = (position) => {
        return new Promise((resolve, reject) => {
            fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + position.lat + '&lon=' + position.lng + '&zoom=18&addressdetails=1')
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    let display_name = '';
                    if (json.address.country) display_name += json.address.country + '|';
                    if (json.address.state) display_name += json.address.state + '|';
                    if (json.address.city && json.address.city != 'Москва') display_name += json.address.city + '|';
                    if (json.address.road) display_name += json.address.road + '|';
                    if (json.address.house_number) display_name += json.address.house_number;

                    json.display_name = display_name.split('|').join(', ');
                    resolve(json);
                }).catch(error => reject(error))
        })
    }

    setCenter = (position) => {
        if (this.map) this.map.setView([position[0], position[1]]);
    }


    render() {
        // console.log(123)
        return (
            <React.Fragment>
                {/* <div className="map-view" id={'map'}>

                </div> */}
                {/* <YMaps/> */}
                <YMaps query={{ apikey: key }} >
                    <Map defaultState={mapData}
                        width="100vw" height="100vh"
                        modules={["templateLayoutFactory", "layout.ImageWithContent"]}
                        onClick={this.clickMap}
                        instanceRef={map => this.map = map}
                    >
                        {/* <SearchControl
                        // instanceRef={ref => {
                        //     if (ref) searchRef.current = ref;
                        // }}
                        /> */}
                        {/* {coordinates.map(coordinate => <Placemark geometry={coordinate} />)} */}
                        <Clusterer
                            options={{
                                preset: 'islands#invertedRedClusterIcons',
                                groupByCoordinates: false,
                                clusterDisableClickZoom: true,
                                clusterHideIconOnBalloonOpen: false,
                                geoObjectHideIconOnBalloonOpen: false,
                            }}
                        >
                            {this.props.markers.map((item) => {
                                let lat = item.latitude.replace(',', '.');
                                let lng = item.longitude.replace(',', '.');
                                const placeMark = {
                                    geometry: [lat, lng],
                                    properties: {
                                        /* hintContent: 'Это хинт', */
                                        balloonContent: '<div class="title">'
                                            + item.name + '</div>' +
                                            '<div class="flex">' +
                                            '<div class="block_info_address"><div class="sub_title">' +
                                            'Время работы' +
                                            '</div>' +
                                            '<div class="value">' + "Пн-Пт " +
                                            moment(item.timeopen, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(item.timeclose, 'HH:mm:ss').format('HH:mm') +
                                            '</div>' +
                                            '</div>' +
                                            '<div class="block_info_address"><div class="sub_title">' +
                                            'Телефон' +
                                            '</div>' +
                                            '<div class="value">' +
                                            item.phone +
                                            '</div>' +
                                            '</div>' +
                                            '</div>'
                                    },
                                    options: {
                                        iconLayout: 'default#imageWithContent',
                                        iconImageHref: require("../../assets/images/marker.svg"),
                                        iconImageSize: [40, 40],
                                        iconImageOffset: [-24, -24]
                                        /* preset: 'islands#redDotIcon' */



                                    },
                                    modules:
                                        ['geoObject.addon.balloon']

                                }
                                return (
                                    <Placemark {...placeMark} onClick={() => this.clickMarker(lat, lng, item.id)}
                                    // {
                                    //     iconImageHref:"../../assets/images/marker.svg",
                                    //     iconImageSize: [30, 42]
                                    // }
                                    />
                                )
                            })}
                        </Clusterer>
                    </Map>
                </YMaps>
            </React.Fragment>
        );
    }
}

MapView.defaultProps = {
    changeMarker: true,
    onChangeAddress: () => { }
}

