import React from "react";
import classNames from "./style.module.scss";

interface Props {
  title: String;
  onClick?: any;
  checked?: boolean;
}

const CheckBox = ({ title, checked ,  onClick}: Props) => {

  let clickCheckbox=()=>{
    // console.log(123)
  }
  return (
    <label className={classNames.check_label} >
      <input type="checkbox" checked={checked || false} onClick={()=>onClick()}></input>
      <span></span>
      {title}
    </label>
  );
};

export default CheckBox;
