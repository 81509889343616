import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Loader, PageContent } from "../../ui";
import { Promotion } from "../../features";
import { RouteComponentProps } from "react-router-dom";
import * as request from "../../requests";
import { EventEmitter } from "../../utils/helpers";

interface State {
  promotions: Array<any>;
  loading: boolean;
}

class PromotionsPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    promotions: [],
    loading: true,
  };

  componentDidMount() {
    EventEmitter.execute('scrollTo', { top: 0 });
    request
      .getPromotions()
      .then((json: any) => {
        console.log(json);
        this.setState({ promotions: json.data.data });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  openPromotion = (id: number) => {
    this.props.history.push("/promotion/" + id);
  };

  onClickButton = (action_url: string) => {
    window.location.href = action_url;
  };

  render() {
    let { promotions, loading } = this.state;
    return (
      <PageContent>
        <Loader loading={loading}>
          {/* <div className={classNames.container}> */}
          {promotions.map((item) => {
            return (
              <Promotion
                full
                onOpen={() => this.openPromotion(item.id)}
                border={true}
                action_button_name={item.action_button_name}
                withButton={item.action_button_name ? true : false}
                image_url={item.image_url}
                action_url={item.action_url}
                onClickButton={() => this.onClickButton(item.action_url)}
              ></Promotion>
            );
          })}
          {/* </div> */}
        </Loader>
      </PageContent>
    );
  }
}
export default PromotionsPage;
