import React from 'react';
import classNames from './style.module.scss';

interface Props {
    icon?: string;
    text?: string;
    onClick?: any;
}

const NavbarButton = ({ icon = undefined, text = undefined, ...props }: Props) => {
    return (
        <button className={classNames.container} {...props}>
            {icon && <img className={classNames.icon} src={require(`../../../assets/images/${icon}`)} />}
            {text && <span className={classNames.text}>{text}</span>}
        </button>
    )
}


export default NavbarButton;