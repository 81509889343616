// import { ServerName } from "../config";
// import { logout } from '../utils';

import { SERVER_NAME } from "../config";

export const request = {
    get: (apiMethod, params) => _requests(apiMethod, params, 'GET'),
    post: (apiMethod, params) => _requests(apiMethod, params, 'POST'),
    put: (apiMethod, params) => _requests(apiMethod, params, 'PUT'),
    delete: (apiMethod, params) => _requests(apiMethod, params, 'DELETE')
}


const _requests = (apiMethod, params, method) => {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        let parameters = {
            method,
            headers
        };
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        // console.log("***********",parameters)
        if (params) parameters.body = JSON.stringify(params);

        fetch(SERVER_NAME + apiMethod, parameters)
            .then(checkResponse)
            .then(json => {
                console.log('success ->', apiMethod, json);
                resolve(json);
            })
            .catch((error) => {
                console.log('error ->', apiMethod, error);
                reject(error);
            })
    })
}

const checkResponse = async response => {
    console.log(response.status);
    const { status } = response;
    switch (status) {
        case 200:
        case 201:
            return response.json();
        case 422:
        case 400:
        case 418:
            return response.json().then(json => {
                throw { status, message: json.msg || json.error || json.data };
            });

        case 204: {
            return;
        }
        case 404: {
            throw { status, message: 'Ресурс недоступен' };
        }
        case 401: {
            throw { status, message: 'Не авторизован' }
        }
        default: {
            throw { status, message: 'Произошла ошибка, проверьте подключение или повторите позднее' }
        }
    }
}